/** @jsxImportSource @emotion/react */
import { Typography, Box, Container, Grid, Link, Button, TextField, Select, MenuItem, FormControl, InputLabel, Snackbar, Menu, Fade, Dialog, Slider } from "@mui/material";
import { useAuth } from "contexts/User";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as JamieImage } from "images/Jamie.svg";
import { ReactComponent as InfoIcon } from "images/InfoIcon.svg";
import Cookies from "js-cookie";
import { doc, updateDoc, addDoc, collection } from "@firebase/firestore";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { limit, orderBy, query, setDoc, where } from "firebase/firestore";
import moment from "moment";
import { Queries, useApolloClient, useLazyQuery } from "services/apollo";
import { useNavigate } from "react-router";
import mixpanel from "mixpanel-browser";
import { Check, KeyboardArrowDown } from "@mui/icons-material";
import { ModalContext } from "contexts/Modal";
import * as Sentry from "@sentry/react";
import { useRemoteConfig } from "contexts/RemoteConfig";
import { CaseEventType, UserActivityTypes, formatPhoneNumber } from "services/Interfaces";
import { useFirebase } from "contexts/Firebase";
import { useSettings } from "contexts/Settings";
import MuiPhoneNumber from 'mui-phone-number';
import PlanOptionsJamie from "./Exit/PlanOptionsJamie";
import { phonePattern } from "services/validation";

interface Message {
  text: string;
  id: string;
  type: string;
  saveTo?: string | Function;
  input?: string;
  validation?: string | Function;
  autoComplete?: string;
  default?: string;
  min?: number;
  max?: number;
  step?: number;
  dataTestId?: string;
  aria?: {
    label?: string;
  }
  headers?: {
    low?: string;
    high?: string;
    mid?: string;
  }
  options?: Array<{
    value: string;
    text: string;
    color?: "primary" | "error" | "inherit" | "secondary" | "info" | "success" | "warning" | undefined; //button
    variant?: "text" | "contained" | "outlined" | undefined; //button
    dataTestId?: string;
  }>
}
let assistant: any = {
  settings: {
    name: "Jamie",
    typeSpeed: 6, //process.env.REACT_APP_TA_SPEED || 12
  },
  variables: {
    section: "initiate",
    step: "",
  },
  lookup: {
    auth: null,
    answers: null,
    apollo: null,
    conciergePhone: window.env.REACT_APP_CONCIERGE_PHONE,
    invalidStates: {
      CA: {
        exchangeUrl: "https://www.coveredca.com/",
        name: "California",
      },
      CO: {
        exchangeUrl: "http://www.connectforhealthco.com/",
        name: "Colorado",
      },
      CT: {
        exchangeUrl: "http://www.accesshealthct.com/",
        name: "Connecticut",
      },
      DC: {
        exchangeUrl: "https://dchealthlink.com/",
        name: "District of Columbia",
      },
      ID: {
        exchangeUrl: "http://www.yourhealthidaho.org/",
        name: "Idaho",
      },
      KY: {
        exchangeUrl: "https://kynect.ky.gov/s/?language=en_US",
        name: "Kentucky",
      },
      ME: {
        exchangeUrl: "https://coverme.gov/",
        name: "Maine",
      },
      MD: {
        exchangeUrl: "http://www.marylandhealthconnection.gov/",
        name: "Maryland",
      },
      MA: {
        exchangeUrl: "https://www.mahealthconnector.org/",
        name: "Massachusetts",
      },
      MN: {
        exchangeUrl: "http://mn.gov/hix/",
        name: "Minnesota",
      },
      NV: {
        exchangeUrl: "https://www.nevadahealthlink.com/",
        name: "Nevada",
      },
      NJ: {
        exchangeUrl: "https://www.getcovered.nj.gov/",
        name: "New Jersey",
      },
      NM: {
        exchangeUrl: "http://www.bewellnm.com/",
        name: "New Mexico",
      },
      NY: {
        exchangeUrl: "http://nystateofhealth.ny.gov/",
        name: "New York",
      },
      PA: {
        exchangeUrl: "https://www.pennie.com/",
        name: "Pennsylvania",
      },
      RI: {
        exchangeUrl: "http://www.healthsourceri.com/",
        name: "Rhode Island",
      },
      VT: {
        exchangeUrl: "http://healthconnect.vermont.gov/",
        name: "Vermont",
      },
      WA: {
        exchangeUrl: "http://www.wahealthplanfinder.org/",
        name: "Washington",
      },
    },
  },
  conversation: {
    initiate: {
      start: "checkAuthed",
      next: "introduction",
      steps: {
        checkAuthed: {
          type: "lookup",
          saveTo: "userAuthed",
          lookup: async function () {
            console.log(`userAuthed lookup`, assistant.lookup.auth);
            return assistant.lookup.auth.authed;
          },
          next: undefined,
        },
      },
    },
    introduction: {
      start: "caseLookUp",
      helper: function () {
        return (
          <Box>
            <Typography data-testid="jamie-intro1" color={"white.main"}>
              Meet Jamie, your Health Insurance Advocate!
            </Typography>
          </Box>
        );
      },
      steps: {
        caseLookUp: {
          type: "lookup",
          saveTo: "caseSynced",
          next: "caseCheck",
          lookup: async () => {
            if (assistant.currentUser?.syncEmployeeId) {
              return true;
            } else {
              return false;
            }
          },
        },
        caseCheck: {
          type: "if",
          variable: "caseSynced",
          check: "==",
          value: true,
          ifTrue: "newUserIntro1",
          ifFalse: "newUserIntro1",
        },
        syncedUserIntro: {
          type: "text",
          dataTestId: "syncedUserIntro",
          text: (assistant: any) => {
            return `Hi ${assistant.currentUser.name.display}, welcome back! <br/>Would you like some help searching for health insurance plans?`;
          },
          next: "needHelpSearchingQ",
        },
        needHelpSearchingQ: {
          type: "question",
          input: "buttons",
          next: "needHelpSearchingA",
          saveTo: "needHelpSearching",
          options: [
            { value: true, text: "Yes", dataTestId: "newUserNeedHelpYes" },
            { value: false, text: "No", dataTestId: "newUserNeedHelpNo" },
          ],
        },
        needHelpSearchingA: {
          type: "if",
          variable: "needHelpSearching",
          check: "==",
          value: true,
          ifTrue: undefined,
          ifFalse: "searchNotProceed",
        },
        searchNotProceed: {
          type: "text",
          dataTestId: "searchNotProceed",
          text: `Not a problem, I’ll be here to help if you need me. Our team of licensed agents are also available at ${process.env.REACT_APP_CONCIERGE_PHONE}`,
          next: "STOP",
        },
        newUserIntro1: {
          type: "text",
          dataTestId: "newUserIntro1",
          text: "Hi, welcome to When!  I am Jamie, your health insurance advocate.",
          next: "newUserIntro2",
        },
        newUserIntro2: {
          type: "text",
          dataTestId: "newUserIntro2",
          text: "I am here to guide you through the complex world of health insurance and to help you find the right plan for your specific needs, budget, and location. In this short chat, I can provide personalized recommendations and support.<br/>In addition to health insurance navigation, your When account gives you access to a variety of support services for periods of transition, whether you’re navigating a job loss, employment changes, or other life events.<br/>Would you like some help searching for health insurance plans?",
          next: "newUserNeedHelpQ",
        },
        newUserNeedHelpQ: {
          type: "question",
          input: "buttons",
          next: "newUserNeedHelpA",
          saveTo: "newUserNeedHelp",
          options: [
            { value: true, text: "Yes", dataTestId: "newUserNeedHelpYes" },
            { value: false, text: "No", dataTestId: "newUserNeedHelpNo" },
          ],
        },
        newUserNeedHelpA: {
          type: "if",
          variable: "newUserNeedHelp",
          check: "==",
          value: true,
          ifTrue: undefined,
          ifFalse: "newUserSearchNotProceed",
        },
        newUserSearchNotProceed: {
          type: "text",
          dataTestId: "newUserSearchNotProceed",
          text: `Not a problem, I’ll be here to help if you need me. Our team of licensed agents are also available at ${process.env.REACT_APP_CONCIERGE_PHONE}`,
          next: "STOP",
        },
      },
      next: "userCase",
    },
    userCase: {
      start: "whenBenefitLookup",
      next: "cobra",
      steps: {
        caseCheck: {
          type: "if",
          variable: "caseSynced",
          check: "==",
          value: true,
          ifTrue: "caseTypeLookup",
          ifFalse: undefined,
        },
        caseTypeLookup: {
          type: "lookup",
          saveTo: "terminationCase",
          next: "caseTypeCheck",
          lookup: async () => {
            if (
              assistant?.case?.event?.type === CaseEventType.termination ||
              assistant?.case?.event?.type ===
                CaseEventType.involuntaryTermination ||
              assistant?.case?.event?.type ===
                CaseEventType.voluntaryTermination ||
              assistant?.case?.event?.type === CaseEventType.hoursReduction
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        caseTypeCheck: {
          type: "if",
          variable: "terminationCase",
          check: "==",
          value: true,
          ifTrue: "cobraLookup",
          ifFalse: "dependentCaseLookup",
        },
        cobraLookup: {
          type: "lookup",
          saveTo: "caseCobra",
          next: "cobraCheck",
          lookup: async () => {
            if (
              assistant?.case?.event?.cobra?.currentContribution ||
              assistant?.case?.event?.cobra?.estimate
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        cobraCheck: {
          type: "if",
          variable: "caseCobra",
          check: "==",
          value: true,
          ifTrue: "cobraCaseIntro",
          ifFalse: "nonCobraCaseIntro",
        },
        cobraCaseIntro: {
          type: "text",
          dataTestId: "cobraCaseIntro",
          text: "I understand you will be losing your employer health insurance coverage.<br/>When you lose your employer health insurance coverage, you will be offered COBRA. COBRA allows you to continue your employer sponsored health insurance, but you will be responsible for the full premium amount.<br/>There are often health insurance alternatives to COBRA that may be a better fit for you. Let’s take a look at some options.",
          next: "whenBenefitLookup",
        },
        nonCobraCaseIntro: {
          type: "text",
          dataTestId: "nonCobraCaseIntro",
          text: "I understand your employment status has changed. Even if you weren’t participating in your employer’s health insurance plan, you may still be eligible to enroll in an individual market health insurance plan during Open Enrollment or if you qualify for a Special Enrollment Period.<br/>Would you like to continue to explore your health insurance options?",
          next: "nonCobraCaseProceedQ",
        },
        nonCobraCaseProceedQ: {
          type: "question",
          input: "buttons",
          next: "nonCobraCaseProceedA",
          saveTo: "nonCobraCaseProceed",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
          ],
        },
        nonCobraCaseProceedA: {
          type: "if",
          variable: "nonCobraCaseProceed",
          check: "==",
          value: true,
          ifTrue: undefined,
          ifFalse: "nonCobraCaseNotProceed",
        },
        nonCobraCaseNotProceed: {
          type: "text",
          text: "Not a problem, I'll be here to help if you need me.",
          next: "goToDashboard",
        },
        goToDashboard: {
          type: "question",
          saveTo: "goToDashboard",
          input: "buttons",
          options: [{ value: true, text: "Go to your Dashboard" }],
          next: "redirectToDashboard",
        },
        redirectToDashboard: {
          type: "redirect",
          url: "/account/dashboard",
        },
        dependentCaseLookup: {
          type: "lookup",
          saveTo: "dependentCase",
          next: "dependentCaseCheck",
          lookup: async () => {
            if (
              assistant?.case?.event?.type ===
              CaseEventType.dependentLossOfCoverage
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        dependentCaseCheck: {
          type: "if",
          variable: "dependentCase",
          check: "==",
          value: true,
          ifTrue: "dependentCobraIntro",
          ifFalse: undefined,
        },
        dependentCobraIntro: {
          type: "text",
          dataTestId: "dependentCobraIntro",
          text: "I understand that you will be losing your previous health insurance coverage on which you were a dependent.<br/>When you lose eligibility for a health insurance plan on which you were a dependent, you will be offered COBRA. COBRA allows you to continue your previous health insurance plan, but you will be responsible for the full premium amount.<br/>There are often health insurance alternatives to COBRA that may be a better fit for you. Let’s take a look at some options.",
          next: "whenBenefitLookup",
        },
        whenBenefitLookup: {
          type: "lookup",
          saveTo: "whenBenefit",
          next: "whenBenefitCheck",
          lookup: async () => {
            if (
              assistant?.whenBenefit?.remainingBenefit &&
              assistant?.whenBenefit?.remainingBenefit > 0
            ) {
              return true;
            } else {
              return false;
            }
          },
        },
        whenBenefitCheck: {
          type: "if",
          variable: "whenBenefit",
          check: "==",
          value: true,
          ifTrue: "whenBenefitInfo",
          ifFalse: undefined,
        },
        whenBenefitInfo: {
          type: "text",
          dataTestId: "whenBenefitInfo",
          text: (assistant: any) => {
            return `Before we get started, I see that <strong> ${assistant?.whenBenefit?.company}</strong> has provided you with a $${assistant?.whenBenefit?.remainingBenefit} <strong> When Benefit </strong> which is a benefit that will reimburse you for health insurance premiums. I will help you find a plan that will allow you to take advantage of this benefit.`;
          },
          next: undefined,
        },
      },
    },
    cobra: {
      start: "benefitPlansLookup",
      next: "personal",
      steps: {
        benefitPlansLookup: {
          type: "lookup",
          saveTo: "benefitPlans",
          next: "benefitPlansCheck",
          lookup: async () => {
            if (assistant.cobraPlans && assistant.cobraPlans.length > 0) {
              return true;
            } else {
              return false;
            }
          },
        },
        benefitPlansCheck: {
          type: "if",
          variable: "benefitPlans",
          check: "==",
          value: true,
          ifTrue: "cobraIntro",
          ifFalse: undefined,
        },
        cobraIntro: {
          type: "text",
          dataTestId: "cobraIntro",
          text: "Let’s start by confirming some information about your COBRA option.",
          next: "cobraConfirmationQ",
        },
        cobraConfirmationQ: {
          type: "text",
          dataTestId: "cobraConfirmationQ",
          text: "Please select or confirm which health insurance plan you were enrolled in.",
          next: "cobraPlanDropdown",
        },
        cobraPlanDropdown: {
          type: "question",
          input: "select",
          options: [
            {
              text: "I was not enrolled in a health insurance plan from my employer",
              value: "none",
            },
          ],
          default: async (assistant: any) => {
            return assistant.planComparison?.name
              ? assistant.planComparison.name
              : null;
          },
          aria: {
            label: "cobraPlan",
          },
          next: "planType",
        },
        planType: {
          type: "text",
          dataTestId: "planType",
          text: "Were you covered as an individual or part of a family?",
          next: "planTypeQuestion",
        },
        planTypeQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "insuranceDetails.type",
          next: "ifCobraPlanSelectedLookup",
          options: [
            {
              value: "individual",
              text: "Just myself",
              dataTestId: "individual",
            },
            { value: "family", text: "My family", dataTestId: "family" },
          ],
        },
        ifCobraPlanSelectedLookup: {
          type: "lookup",
          saveTo: "cobraPlanSelected",
          next: "cobraPlanSelectedCheck",
          lookup: async () => {
            if (assistant.planComparison?.name) {
              return true;
            } else {
              return false;
            }
          },
        },
        cobraPlanSelectedCheck: {
          type: "if",
          variable: "cobraPlanSelected",
          check: "==",
          value: true,
          ifTrue: "cobraEstimateLookup",
          ifFalse: "noCobraPlanProceed",
        },
        cobraPlanType: {
          type: "text",
          dataTestId: "cobraPlanType",
          text: "Would you like me to help you find an individual plan just for yourself, or a plan that covers you and other members of your household?",
          next: "cobraPlanTypeQuestion",
        },
        cobraPlanTypeQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "insuranceDetails.type",
          next: "cobraEstimateLookup",
          options: [
            {
              value: "individual",
              text: "Just myself",
              dataTestId: "cobra-individual",
            },
            { value: "family", text: "My family", dataTestId: "cobra-family" },
          ],
        },
        noCobraPlanProceed: {
          type: "text",
          dataTestId: "noCobraPlanProceed",
          text: "No problem, we can help without that information.",
          next: "cobraEstimateLookup",
        },
        cobraEstimateLookup: {
          type: "lookup",
          saveTo: "cobraEstimateExists",
          next: "cobraEstimateCheck",
          lookup: async () => {
            const estimate = await assistant.lookup.answers.get(
              "cobra.estimate"
            );
            if (estimate && estimate > 0) {
              return true;
            } else {
              return false;
            }
          },
        },
        cobraEstimateCheck: {
          type: "if",
          variable: "cobraEstimateExists",
          check: "==",
          value: true,
          ifTrue: "confirmCobraEstimate",
          ifFalse: "askCobraEstimate",
        },
        confirmCobraEstimate: {
          type: "text",
          dataTestId: "confirmCobraEstimate",
          text: async (assistant: any) => {
            const estimate = await assistant.lookup.answers.get(
              "cobra.estimate"
            );
            return `Great, our records indicate that if you were to enroll in COBRA, the monthly premium that you would pay is <strong> $${estimate} </strong>. Is that correct?`;
          },
          next: "confirmCobraEstimateQuestion",
        },
        confirmCobraEstimateQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "confirmCobraEstimate",
          next: "confirmCobraEstimateCheck",
          options: [
            { value: "Yes", text: "Yes", dataTestId: "yes" },
            { value: "No", text: "No", dataTestId: "no" },
            {
              value: "I don’t know my COBRA premium",
              text: "I don’t know my COBRA premium",
              dataTestId: "DoNotKnow",
            },
          ],
        },
        confirmCobraEstimateCheck: {
          type: "if",
          variable: () => {
            const confirmCobraEstimate = assistant.lookup.answers.get(
              "confirmCobraEstimate"
            );
            if (confirmCobraEstimate === "No") {
              return true;
            } else {
              return false;
            }
          },
          check: "==",
          value: true,
          ifTrue: "enterCobraPremiumQuestion",
          ifFalse: "checkUnknownCobraPremium",
        },
        checkUnknownCobraPremium: {
          type: "if",
          variable: () => {
            const confirmCobraEstimate = assistant.lookup.answers.get(
              "confirmCobraEstimate"
            );
            return confirmCobraEstimate === "I don’t know my COBRA premium";
          },
          check: "==",
          value: true,
          ifTrue: "unknownCobraEstimate",
          ifFalse: undefined,
        },
        unknownCobraEstimate: {
          type: "text",
          dataTestId: "unknownCobraEstimate",
          text: "No problem, we can help without that information.",
          next: undefined,
        },
        askCobraEstimate: {
          type: "text",
          dataTestId: "askCobraEstimate",
          text: async (assistant: any) => {
            return `If ${assistant?.whenBenefit?.company} has provided you with your COBRA notification, what is your quoted COBRA premium?`;
          },
          next: "askCobraEstimateQuestion",
        },
        askCobraEstimateQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "askCobraEstimate",
          next: "askCobraEstimateCheck",
          options: [
            {
              value: true,
              text: "Enter premium amount",
              dataTestId: "enterPremiumAmount",
            },
            {
              value: false,
              text: "I don’t know my COBRA premium",
              dataTestId: "doNotKnow",
            },
          ],
        },
        askCobraEstimateCheck: {
          type: "if",
          variable: "askCobraEstimate",
          check: "==",
          value: true,
          ifTrue: "enterCobraPremiumQuestion",
          ifFalse: undefined,
        },
        enterCobraPremiumQuestion: {
          type: "text",
          text: "What is the COBRA premium shown on your enrollment packet?",
          next: "enterCobraPremium",
        },
        enterCobraPremium: {
          type: "question",
          saveTo: "cobra.estimate",
          validation: "^\\d+(\\.\\d{1,2})?$",
          aria: {
            label: "enterCobraPremium",
          },
          default: (assistant: any) => {
            return assistant.lookup.answers.get("cobra.estimate");
          },
          input: "string",
          next: undefined,
        },
      },
    },
    personal: {
      start: "caseCheck",
      next: "household",
      helper: function () {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography color="white.main">
              Personal information is collected so that I can identify you and
              provide you personalized results!
            </Typography>
            <Typography color="white.main">
              Don&apos;t worry, I won&apos;t send your personal information to
              our carrier partners until you pick a plan to enroll in!
            </Typography>
          </Box>
        );
      },
      steps: {
        caseCheck: {
          type: "if",
          variable: "caseSynced",
          check: "==",
          value: true,
          ifTrue: "intro",
          ifFalse: "firstName",
        },
        intro: {
          type: "text",
          text: "Let's confirm your info so we can find plans in the marketplace that are available to you.",
          next: "firstName",
          dataTestId: "confirmInfoPrompt",
        },
        firstName: {
          type: "text",
          text: async (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.firstName")
              ? "Confirm your first name"
              : "Enter your first name";
          },
          next: "firstNameQ",
          dataTestId: "firstNamePrompt",
        },
        firstNameQ: {
          type: "question",
          saveTo: "personalInfo.firstName",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.firstName");
          },
          autoComplete: "given-name",
          validation: "[\\w]{2,}",
          aria: {
            label: "First Name",
          },
          input: "text",
          next: "email",
          dataTestId: "firstName-text-field",
        },
        email: {
          type: "text",
          text: async (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.email")
              ? "Confirm your email address"
              : "Enter your email address";
          },
          next: "emailQ",
        },
        emailQ: {
          type: "question",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.email");
          },
          validation:
            "^[\\wa-z0-9!#$%&'*+\\/=?^_‘{|}~-]+(?:\\.[\\wa-z0-9!#$%&'*+\\/=?^_‘{|}~-]+)*@(?:[\\wa-z0-9](?:[\\wa-z0-9-]*[\\wa-z0-9])?\\.)+[\\wa-z0-9](?:[\\wa-z0-9-]*[\\wa-z0-9])?$",
          saveTo: "personalInfo.email",
          autoComplete: "email",
          input: "text",

          aria: {
            label: "Email",
          },
          next: "phoneNumber",
          dataTestId: "email-text-field",
        },
        phoneNumber: {
          type: "text",
          text: async (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.phone")
              ? "Confirm your phone number"
              : "Enter your phone number";
          },
          next: "phoneQ",
        },
        phoneQ: {
          type: "question",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.phone");
          },
          validation: phonePattern.toString().slice(1, -1),
          saveTo: "personalInfo.phone",
          autoComplete: "tel",
          input: "text",
          aria: {
            label: "Phone Number",
          },
          next: "selfDOB",
          dataTestId: "phone-text-field",
        },
        selfDOB: {
          type: "text",
          text: "What is your Date of Birth?",
          next: "selfDOBQ",
        },
        selfDOBQ: {
          type: "question",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.dateOfBirth");
          },
          input: "date",
          saveTo: "insuranceDetails.household.members.self.dob",
          next: "checkAge",
          aria: {
            label: "SELF-DOB",
          },
          dataTestId: "dob-date-field",
        },
        checkAge: {
          type: "if",
          variable: () => {
            const today = new Date();
            const birthDate = new Date(
              assistant.lookup.answers.get("personalInfo.dateOfBirth")
            );

            // Calculate the age
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            if (
              monthDiff < 0 ||
              (monthDiff === 0 && today.getDate() < birthDate.getDate())
            ) {
              age--;
            }
            return age >= 65;
          },
          check: "==",
          value: true,
          ifTrue: "over65Description",
          ifFalse: "zipCodeIntro",
        },
        over65Description: {
          type: "text",
          text: `As someone over 65, you may be eligible for Medicare. When’s licensed and helpful agents can offer a variety of Medicare Supplement and Medicare Advantage plans over the phone, but we are unable to offer these plans online. If you’d like to explore your Medicare Supplement or Medicare Advantage plan options, call us at ${process.env.REACT_APP_CONCIERGE_PHONE}. Or, if you’d like to proceed online, I can help you find a plan from the ACA marketplace or with one of our private insurance carrier partners.`,
          next: "over65Description2",
          dataTestId: "over65Description2-txt",
        },
        over65Description2: {
          type: "text",
          text: "Would you like to proceed?",
          next: "over65Q",
        },
        over65Q: {
          type: "question",
          input: "buttons",
          saveTo: "over65Proceed",
          next: "over65Proceed",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
          ],
        },
        over65Proceed: {
          type: "if",
          variable: "over65Proceed",
          check: "==",
          value: true,
          ifTrue: "zipCodeIntro",
          ifFalse: "over65NotProceed",
        },
        over65NotProceed: {
          type: "text",
          text: `No problem. If you’d like to get live support to find a health insurance plan, including Medicare Supplement and Medicare Advantage plans, don’t hesitate to give us a call at ${process.env.REACT_APP_CONCIERGE_PHONE}. We’re here to help!`,
          next: "goToDashboard",
        },
        goToDashboard: {
          type: "question",
          saveTo: "goToDashboard",
          input: "buttons",
          options: [{ value: true, text: "Go to your Dashboard" }],
          next: "redirectToDashboard",
        },
        redirectToDashboard: {
          type: "redirect",
          url: "/account/dashboard",
        },
        zipCodeIntro: {
          type: "text",
          text: "Great! Now let’s check the plans that are available in your area.  Please confirm or enter your 5-digit zip code",
          next: "zipcodeQ",
          dataTestId: "zipCodeIntro-text",
        },
        zipcodeQ: {
          type: "question",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("insuranceDetails.zipcode");
          },
          saveTo: "insuranceDetails.zipcode",
          validation: "(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)",
          autoComplete: "postal-code",
          input: "text",

          aria: {
            label: "Zip Code",
          },
          next: "countyLookup",
          dataTestId: "zipCode-text-field",
          helper: function () {
            return (
              <Box>
                <Typography color="white.main">
                  Your zipcode is used to find policies available in your area.
                </Typography>
              </Box>
            );
          },
        },
        countyLookup: {
          type: "lookup",
          saveTo: "insuranceDetails.counties",
          async: true,
          next: "checkCounty",
          lookup: async () => {
            try {
              // query counties
              console.log("get counties");
              const counties = await assistant.lookup.apollo.query({
                query: Queries.GET_GEOGRAPHY,
                variables: {
                  zipcode: assistant.lookup.answers.get(
                    "insuranceDetails.zipcode"
                  ),
                },
              });
              console.log(`counties `, counties.data);
              await assistant.updateDoc(
                "personalInfo.state",
                counties.data.geographyByZip[0].state
              );
              console.log(
                `saved state ${
                  counties.data.geographyByZip[0].state
                } as ${assistant.lookup.answers.get("personalInfo.state")}`
              );
              return counties.data.geographyByZip.map(
                (county: {
                  fips: string;
                  name: string;
                  state: string;
                  zipcode: string;
                }) => {
                  return { text: county.name, value: county };
                }
              );
            } catch (err) {
              console.log("personal.countyLookup error", err);
            }
          },
        },
        checkCounty: {
          type: "if",
          variable: () => {
            return assistant.lookup.answers.get("insuranceDetails.counties")
              ? assistant.lookup.answers.get("insuranceDetails.counties").length
              : 0;
          },
          check: ">",
          value: 0,
          ifTrue: "county",
          ifFalse: "countyLookupText",
        },
        countyLookupText: {
          type: "text",
          text: "Please give me a minute while I lookup your county.",
          next: "countyLookupQ",
        },
        countyLookupQ: {
          type: "lookup",
          saveTo: "insuranceDetails.counties",
          next: "county",
          lookup: async () => {
            // query counties
            try {
              console.log("get counties");
              const counties = await assistant.lookup.apollo.query({
                query: Queries.GET_GEOGRAPHY,
                variables: {
                  zipcode: assistant.lookup.answers.get(
                    "insuranceDetails.zipcode"
                  ),
                },
              });
              console.log(`counties `, counties.data);
              return counties.data.geographyByZip.map(
                (county: {
                  fips: string;
                  name: string;
                  state: string;
                  zipcode: string;
                }) => {
                  return { text: county.name, value: county };
                }
              );
            } catch (err) {
              console.log(`household.countyLookup error`, err);
            }
          },
        },
        county: {
          type: "text",
          text: "Select your county",
          next: "countyQ",
        },
        countyQ: {
          type: "question",
          input: "buttons",
          options: "insuranceDetails.counties",
          saveTo: "insuranceDetails.county",
          next: "emailEnroll",
          dataTestId: "country",
        },
        emailEnroll: {
          type: "action",
          next: "checkStateLoaded",
          action: async () => {
            const email = await assistant.lookup.answers.get(
              "personalInfo.email"
            );
            const firstName = await assistant.lookup.answers.get(
              "personalInfo.firstName"
            );
            const state = await assistant.lookup.answers.get(
              "personalInfo.state"
            );
            const phone = await assistant.lookup.answers.get(
              "personalInfo.phone"
            );
            mixpanel.people.set("$email", email);
            let data = new URLSearchParams();
            data.set("u", "7a3de92b76c96ed660f691fc4");
            data.set("id", "14c1cf45f0");
            data.set("MERGE0", email);
            data.set("MERGE1", firstName);
            data.set("MERGE6", state);
            data.set("MERGE9", phone);
            data.set("group[214962][1]", "1");
            data.set("group[214962][4]", "1");
            try {
              await fetch(
                "https://forwhen.us18.list-manage.com/subscribe/post?" +
                  data.toString(),
                {
                  method: "post",
                }
              );
            } catch (err) {
              // ignore errors
            }
          },
        },
        checkStateLoaded: {
          type: "if",
          variable: () => {
            return assistant.lookup.answers.get("personalInfo.state");
          },
          check: "==",
          value: undefined,
          ifTrue: "reloadState",
          ifFalse: "checkInvalidState",
        },
        reloadState: {
          type: "text",
          text: "Please wait while I make sure we have coverage options in your state.",
          next: "stateLookup",
        },
        stateLookup: {
          type: "lookup",
          saveTo: "insuranceDetails.counties",
          async: false,
          next: "checkInvalidState",
          lookup: async () => {
            try {
              // query counties
              console.log("get counties");
              const counties = await assistant.lookup.apollo.query({
                query: Queries.GET_GEOGRAPHY,
                variables: {
                  zipcode: assistant.lookup.answers.get(
                    "insuranceDetails.zipcode"
                  ),
                },
              });
              console.log(`counties `, counties.data);
              await assistant.updateDoc(
                "personalInfo.state",
                counties.data.geographyByZip[0].state
              );
              console.log(
                `saved state ${
                  counties.data.geographyByZip[0].state
                } as ${assistant.lookup.answers.get("personalInfo.state")}`
              );
              return counties.data.geographyByZip.map(
                (county: {
                  fips: string;
                  name: string;
                  state: string;
                  zipcode: string;
                }) => {
                  return { text: county.name, value: county };
                }
              );
            } catch (err) {
              console.log("stateLookup error", err);
            }
          },
        },
        checkInvalidState: {
          type: "if",
          variable: () => {
            if (assistant.remoteConfig.quotit) {
              // with quotit all states are valid
              return false;
            }
            return Object.keys(assistant.lookup.invalidStates).includes(
              assistant.lookup.answers.get("personalInfo.state")
            );
          },
          check: "==",
          value: true,
          ifTrue: "invalidStateNotice",
          ifFalse: "validState",
        },
        validState: {
          type: "text",
          text: "Great, you live in a state that I am able to offer health insurance plans for!",
          dataTestId: "validate-state-text",
          next: undefined,
        },
        invalidStateNotice: {
          type: "text",
          text: `You live in a state that has a state-sponsored health insurance exchange. Unfortunately, I can’t show you plans from these exchanges online, but I can offer some private plan options.  Otherwise, our team of licensed agents can walk you through the ACA plans available to you over the phone. <br/>If you’d like to speak to an agent, call ${process.env.REACT_APP_CONCIERGE_PHONE}. Or, if you’d like to proceed online, I can help you find a plan with one of our private insurance carrier partners.<br/>Would you like to proceed?`,
          next: "invalidStateNoticeQuestion",
        },
        invalidStateNoticeQuestion: {
          type: "question",
          input: "buttons",
          next: "nonCobraCaseProceedCheck",
          saveTo: "invalidStateNoticeProceed",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
          ],
        },
        nonCobraCaseProceedCheck: {
          type: "if",
          variable: "invalidStateNoticeProceed",
          check: "==",
          value: true,
          ifTrue: undefined,
          ifFalse: "nonCobraCaseNotProceed",
        },
        nonCobraCaseNotProceed: {
          type: "text",
          text: `No problem. If you’d like to get live support to find a health insurance plan, including plans offered on your state’s marketplace, don’t hesitate to give us a call at ${process.env.REACT_APP_CONCIERGE_PHONE}. We’re here to help!`,
          next: "goToDashboard",
        },
      },
    },
    household: {
      start: "selfName",
      next: "policies",
      helper: function () {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography color="white.main">
              Information about your household is required to build health
              insurance plans and to get you rates that accurately reflect what
              you need.
            </Typography>
            <Typography color="white.main">
              The information you provide here will be shared with our carrier
              partners should you choose to enroll in one of their plans.
            </Typography>
            <Typography color="white.main">
              If you need protection for your and your family be sure to
              indicate that here so that we can collect information about each
              member of your household. Plans for yourself do not allow you to
              add additional members.
            </Typography>
            <Typography color="white.main">
              The name of additional members in your household is only used to
              help you identify these members when making changes in the future.
              This information is not shared with partners, and you can use
              &apos;Child 1&apos; in lieu of actual names.
            </Typography>
          </Box>
        );
      },
      steps: {
        selfName: {
          type: "lookup",
          saveTo: "insuranceDetails.household.members.self.name",
          lookup: async () => {
            return "self";
          },
          next: "selfRelationship",
        },
        selfRelationship: {
          type: "lookup",
          saveTo: "insuranceDetails.household.members.self.relationship",
          lookup: async () => {
            return "self";
          },
          next: "selfGender",
        },
        selfGender: {
          type: "text",
          text: "What is your gender?",
          next: "selfGenderQ",
        },
        selfGenderQ: {
          type: "question",
          input: "buttons",
          saveTo: "insuranceDetails.household.members.self.gender",
          next: "selfIfPregnant",
          options: [
            { value: "male", text: "Male", dataTestId: "option-male" },
            { value: "female", text: "Female", dataTestId: "option-female" },
          ],
        },
        selfIfPregnant: {
          type: "if",
          variable: "insuranceDetails.household.members.self.gender",
          check: "==",
          value: "female",
          ifTrue: "selfPregnant",
          ifFalse: "selfSmokes",
        },
        selfPregnant: {
          type: "text",
          text: "Are you pregnant?",
          next: "selfPregnantQuestion",
        },
        selfPregnantQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "insuranceDetails.household.members.self.pregnant",
          next: "selfSmokes",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
          ],
        },
        selfSmokes: {
          type: "text",
          text: "Do you use tobacco products? (e.g. cigarettes, vape pens, Zyns, etc.)",
          next: "selfSmokesQuestion",
          dataTestId: "selfSmokes-text",
        },
        selfSmokesQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "insuranceDetails.household.members.self.tobacco",
          next: "moreHousehold",
          options: [
            { value: true, text: "Yes", dataTestId: "option-smoke-yes" },
            { value: false, text: "No", dataTestId: "option-smoke-no" },
          ],
        },
        moreHousehold: {
          type: "text",
          text: (assistant: any) => {
            const householdMembers =
              assistant.lookup.answers.get(
                "insuranceDetails.household.members"
              ) || {};
            const memberCount = Object.keys(householdMembers).filter(
              (key) => key !== "self"
            ).length;

            if (memberCount === 0) {
              return "Now we will need some information about the people you want to cover on your health insurance plan. The dependents you include here may be different than those included in your previous plan. Would you like to add dependents to your search?";
            } else {
              return `Would you like to add any more dependents?`;
            }
          },
          next: "moreHouseholdQuestion",
        },
        moreHouseholdQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "moreHousehold",
          next: "ifMoreHousehold",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
          ],
        },
        ifMoreHousehold: {
          type: "if",
          variable: "moreHousehold",
          check: "==",
          value: true,
          ifTrue: "familyStart",
          ifFalse: "policyLookup",
        },
        familyStart: {
          type: "text",
          text: "Now we will collect information about the members of your household",
          next: "addMember",
        },
        addMember: {
          type: "text",
          text: "What is the name of this household member?",
          next: "addMemberQ",
        },
        addMemberQ: {
          type: "question",
          input: "text",
          validation: "[\\w]{2,}",
          aria: {
            label: "Household member name",
          },
          saveTo: async (value: string) => {
            console.log("addMember", value);
            await assistant.updateDoc(`householdMemberName`, value);
            await assistant.updateDoc(
              `insuranceDetails.household.members.${value}.name`,
              value
            );
          },
          next: "memberGender",
        },
        memberGender: {
          type: "text",
          text: (assistant: any) => {
            return `What gender is ${assistant.lookup.answers.get(
              "householdMemberName"
            )}?`;
          },
          next: "memberGenderQ",
        },
        memberGenderQ: {
          type: "question",
          input: "buttons",
          next: "memberDOB",
          saveTo: async (value: string) => {
            await assistant.updateDoc(
              `insuranceDetails.household.members.${assistant.lookup.answers.get(
                "householdMemberName"
              )}.gender`,
              value
            );
          },
          options: [
            { value: "male", text: "Male", dataTestId: "option-male" },
            { value: "female", text: "Female", dataTestId: "option-female" },
          ],
        },
        memberDOB: {
          type: "text",
          text: (assistant: any) => {
            return `What is ${assistant.lookup.answers.get(
              "householdMemberName"
            )}'s date of birth?`; // TODO add apostrophe
          },
          next: "memberDOBQ",
        },
        memberDOBQ: {
          type: "question",
          input: "date",
          next: "memberIfPregnant",
          saveTo: async (value: string) => {
            await assistant.updateDoc(
              `insuranceDetails.household.members.${assistant.lookup.answers.get(
                "householdMemberName"
              )}.dob`,
              value
            );
          },
        },
        memberIfPregnant: {
          type: "if",
          variable: () => {
            return assistant.lookup.answers.get(
              `insuranceDetails.household.members.${assistant.lookup.answers.get(
                "householdMemberName"
              )}.gender`
            );
          },
          check: "==",
          value: "female",
          ifTrue: "memberPregnant",
          ifFalse: "memberSmokes",
        },
        memberPregnant: {
          type: "text",
          text: (assistant: any) => {
            return `Is ${assistant.lookup.answers.get(
              "householdMemberName"
            )} pregnant?`;
          },
          next: "memberPregnantQuestion",
        },
        memberPregnantQuestion: {
          type: "question",
          input: "buttons",
          next: "memberSmokes",
          saveTo: async (value: string) => {
            await assistant.updateDoc(
              `insuranceDetails.household.members.${assistant.lookup.answers.get(
                "householdMemberName"
              )}.pregnant`,
              value
            );
          },
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
          ],
        },
        memberSmokes: {
          type: "text",
          text: (assistant: any) => {
            return `Does ${assistant.lookup.answers.get(
              "householdMemberName"
            )} use tobacco products? (e.g. cigarettes, vape pens, Zyns, etc.)`;
          },
          next: "memberSmokesQuestion",
        },
        memberSmokesQuestion: {
          type: "question",
          input: "buttons",
          next: "memberRelationship",
          saveTo: async (value: string) => {
            await assistant.updateDoc(
              `insuranceDetails.household.members.${assistant.lookup.answers.get(
                "householdMemberName"
              )}.tobacco`,
              value
            );
          },
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
          ],
        },
        memberRelationship: {
          type: "question",
          input: "select",
          text: (assistant: any) => {
            return `How is ${assistant.lookup.answers.get(
              "householdMemberName"
            )} related to you?`;
          },
          aria: {
            label: "memberRelationship",
          },
          next: "moreHousehold",
          saveTo: async (value: string) => {
            await assistant.updateDoc(
              `insuranceDetails.household.members.${assistant.lookup.answers.get(
                "householdMemberName"
              )}.relationship`,
              value
            );
          },
          options: [
            { value: "spouse", text: "Spouse" },
            { value: "child", text: "Child" },
          ],
        },
        policyLookup: {
          type: "lookup",
          saveTo: "searchPlans",
          async: true,
          next: "subsidyEligibility",
          lookup: async () => {
            if (assistant.remoteConfig.quotit) {
              // use getRates
              let plans = await assistant.lookup.apollo.query({
                query: Queries.getRates,
              });
              return plans.data.getRates;
            } else {
              let plans = await assistant.lookup.apollo.query({
                query: Queries.SEARCH_PLANS,
              });
              return plans.data.searchPlans;
            }
          },
        },
        subsidyEligibility: {
          type: "text",
          text: "The federal and state health insurance exchanges offer subsidies to help lower the premium of health insurance plans bought through the marketplaces in the form of Advance Premium Tax Credits, or APTCs. Would you like to check if you see if you are eligible for savings?",
          next: "subsidyEligibilityQ",
          dataTestId: "subsidyEligibility-text",
        },
        subsidyEligibilityQ: {
          type: "question",
          input: "buttons",
          saveTo: "checkSubsidy",
          next: "checkSubsidy",
          options: [
            { value: true, text: "Yes", dataTestId: "subsidyEligibilityQ-yes" },
            { value: false, text: "No", dataTestId: "subsidyEligibilityQ-no" },
          ],
        },
        checkSubsidy: {
          type: "if",
          variable: "checkSubsidy",
          check: "==",
          value: true,
          ifTrue: "taxFiling",
          ifFalse: undefined,
        },
        taxFiling: {
          type: "text",
          text: "How do you file your taxes?",
          next: "taxFilingQ",
          dataTestId: "taxFiling-text",
        },
        taxFilingQ: {
          type: "question",
          input: "select",
          default: async (assistant: any) => {
            return assistant.lookup.answers.get(
              "insuranceDetails.taxFilingStatus"
            );
          },
          saveTo: "insuranceDetails.taxFilingStatus",
          options: [
            {
              value: "Single",
              text: "Single",
            },
            {
              value: "HeadOfHousehold",
              text: "Head of household",
            },
            {
              value: "QualifyingWidower",
              text: "Qualifying widower",
            },
            {
              value: "MarriedFilingJointly",
              text: "Married filing jointly",
            },
            {
              value: "MarriedFilingSeparately",
              text: "Married filing separately",
            },
          ],
          next: "income",
        },
        income: {
          type: "text",
          text: "What is your estimated income of your entire household for this year? For guidance on the best way to answer this question, see this help article in our Support Center.",
          helpText:
            "Income should be based on what you will report to the IRS for taxes. ACA subsidies use this value",
          next: "incomeQuestion",
          dataTestId: "income-text",
        },
        incomeQuestion: {
          type: "question",
          input: "number",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("insuranceDetails.income");
          },
          saveTo: "insuranceDetails.income",
          next: "isNativeAmerican",
          dataTestId: "incomeQuestion-number-field",
        },
        isNativeAmerican: {
          type: "text",
          text: "Are you a member of a federally recognized Native American Tribe?",
          next: "isNativeAmericanQ",
          dataTestId: "isNativeAmerican-text",
        },
        isNativeAmericanQ: {
          type: "question",
          input: "buttons",
          next: "subsidiesCheckLookup",
          saveTo: "insuranceDetails.isNativeAmarican",
          options: [
            { value: true, text: "Yes", dataTestId: "isNativeAmericanQ-yes" },
            { value: false, text: "No", dataTestId: "isNativeAmericanQ-no" },
          ],
        },
        subsidiesCheckLookup: {
          type: "lookup",
          async: true,
          saveTo: "insuranceDetails.federalSubsidyAmount",
          next: undefined,
          lookup: async () => {
            await assistant.lookup.apollo.query({
              query: Queries.subsidyCheck,
              variables: {
                uid: assistant.currentUser.uid,
              },
            });
            return;
          },
        },
      },
    },
    policies: {
      start: "lookup",
      next: "preferences",
      helper: function () {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography color="white.main">
              When is partnered with Healthcare.gov, private medical providers,
              and short term medical providers to provide you with the most
              robust offering of health insurance products available.
            </Typography>
            {assistant.lookup.answers.get("searchPlans.total") > 0 && (
              <Typography color="white.main">
                I was able to find{" "}
                {assistant.lookup.answers.get("searchPlans.total")} health
                insurance plans based on the information you&apos;ve provided!
              </Typography>
            )}
            <Typography color="white.main">
              I only send non-identifiable information to our carrier partners
              to get a list of health insurance policies and rates.
            </Typography>
            <Typography color="white.main">
              I highly recommend completing the recommendation process so that I
              can find the most suitable plans for you.
            </Typography>
          </Box>
        );
      },
      steps: {
        checkPolicyCount: {
          type: "if",
          variable: "searchPlans.total",
          check: ">",
          value: 0,
          ifTrue: "stateCheck",
          ifFalse: "lookupText",
        },
        lookupText: {
          type: "text",
          text: "Please give me a second while I see how many policies I can find for you.",
          next: "lookup",
        },
        lookup: {
          type: "lookup",
          saveTo: "searchPlans",
          next: "stateCheck",
          lookup: async () => {
            if (assistant.remoteConfig.quotit) {
              // use getRates
              let plans = await assistant.lookup.apollo.query({
                query: Queries.getRates,
              });
              console.log("++++++++++++++", plans.data.getRates);
              return plans.data.getRates;
            } else {
              let plans = await assistant.lookup.apollo.query({
                query: Queries.SEARCH_PLANS,
              });
              console.log("-------------", plans.data.searchPlans);
              return plans.data.searchPlans;
            }
          },
        },
        stateCheck: {
          type: "if",
          variable: "searchPlans.total",
          check: "==",
          value: 0,
          ifTrue: "searchError",
          ifFalse: "intro",
        },
        searchError: {
          type: "text",
          text: (assistant: any) => {
            return `Unfortunately I don't have any plans to offer you. For now I can only search plans on healthcare.gov, but I will soon be able to access plans directly from major medical providers to offer you options. Can I email you once those are available?`;
          },
          next: "emailEnroll",
        },
        emailEnroll: {
          type: "question",
          input: "buttons",
          next: "emailSubscribedCheck",
          saveTo: async (value: string) => {
            //mailchimp enroll
            if (value === "yes") {
              try {
                const email = await assistant.lookup.answers.get(
                  "personalInfo.email"
                );
                const firstName = await assistant.lookup.answers.get(
                  "personalInfo.firstName"
                );
                const state = await assistant.lookup.answers.get(
                  "insuranceDetails.county.state"
                );
                const phone = await assistant.lookup.answers.get(
                  "personalInfo.phone"
                );
                const dob = moment(
                  await assistant.lookup.answers.get(
                    "insuranceDetails.household.members.self.dob"
                  )
                );
                let data = new URLSearchParams();
                data.set("u", "7a3de92b76c96ed660f691fc4");
                data.set("id", "14c1cf45f0");
                data.set("MERGE0", email);
                data.set("MERGE1", firstName);
                data.set("MERGE6", state);
                data.set("MERGE9", phone);
                data.set("MERGE5[month]", (dob.month() + 1).toString());
                data.set("MERGE5[day]", dob.date().toString());
                data.set("group[214962][1]", "1");

                await fetch(
                  "https://forwhen.us18.list-manage.com/subscribe/post?" +
                    data.toString(),
                  {
                    method: "post",
                  }
                );
              } catch (err) {
                // ignore errors
              }
              await assistant.updateDoc("emailSubscribed", true);
            } else {
              await assistant.updateDoc("emailSubscribed", false);
            }
          },
          options: [
            { value: "yes", text: "Yes" },
            { value: "no", text: "No" },
          ],
        },
        emailSubscribedCheck: {
          type: "if",
          variable: "emailSubscribed",
          check: "==",
          value: true,
          ifTrue: "emailThankYou",
          ifFalse: "emailDone",
        },
        emailThankYou: {
          type: "text",
          text: "Thank you for subscribing, I look forward to sending you plan options soon!",
          next: "STOP",
        },
        emailDone: {
          type: "text",
          text: "I hope that next time you visit I will have additional options available for you!",
          next: "STOP",
        },
        intro: {
          type: "text",
          text: (assistant: any) => {
            return `Based on the information you provided, I’ve found  ${assistant.lookup.answers.get(
              "searchPlans.total"
            )} plans that are more affordable than your COBRA option. Here’s a preview of just three of them:`;
          },
          next: "PlanOptionsTable",
          dataTestId: "intro-text",
        },
        PlanOptionsTable: {
          type: "planOptionTable",
          next: "askToRefineInfo",
          dataTestId: "PlanOptionsTable-planOptionTable",
        },
        askToRefineInfo: {
          type: "text",
          text: "If you are interested in these plans, you can view them here. Or, I can provide more tailored recommendations if you’ll answer a few short questions about your health plan preferences. May I ask more questions to help me better understand your healthcare needs, budget, and preferences?",
          next: "askToRefineQuestion",
          dataTestId: "askToRefineInfo-text",
        },
        askToRefineQuestion: {
          type: "question",
          input: "buttons",
          next: "ifRefine",
          saveTo: "refineQuestions",
          options: [
            {
              value: true,
              text: "Yes, refine my search",
              dataTestId: "askToRefineQuestion-yes",
            },
            {
              value: false,
              text: "No, take me to my plans",
              dataTestId: "askToRefineQuestion-no",
            },
          ],
        },
        ifRefine: {
          type: "if",
          variable: "refineQuestions",
          check: "==",
          value: true,
          ifTrue: undefined,
          ifFalse: "redirectToPolicies",
        },
        redirectToPolicies: {
          type: "redirect",
          url: "/jamie/recommended-plans",
        },
      },
    },
    preferences: {
      start: "planType",
      helper: function () {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography color="white.main">
              Your health insurance preferences are important to me!
            </Typography>
            <Typography color="white.main">
              I will use this information to help pre-sort policies and make
              recommendations that most resemble your preferences.
            </Typography>
            <Typography color="white.main">
              While I can&apos;t know everything about you and your family this
              information gives me a good jump-start to finding the right health
              insurance plan for you.
            </Typography>
          </Box>
        );
      },
      steps: {
        intro: {
          type: "text",
          text: "Now I want to learn more about the what kind of insurance plan you are looking for now.",
          next: "planType",
        },
        planType: {
          type: "text",
          text: "There are three main types of health insurance networks to pick from. A PPO, which typically has a higher premium but more flexibility in choosing doctors. An HMO, which has a lower premium but less flexibility in choosing doctors. And a short term medical plan which is a temporary plan that is cheaper but has less coverage. Please tell me your preferences for each of these types of plans.",
          next: "ppoQ",
          dataTestId: "planType-text",
        },
        ppoQ: {
          type: "question",
          saveTo: "$preferences.planType.ppo",
          input: "slider",
          text: "PPO",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Interested",
            mid: "Somewhat Interested",
            high: "Strongly Prefer",
          },
          next: "hmoQ",
        },
        hmoQ: {
          type: "question",
          saveTo: "$preferences.planType.hmo",
          input: "slider",
          text: "HMO",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Interested",
            mid: "Somewhat Interested",
            high: "Strongly Prefer",
          },
          next: "shortTermQ",
        },
        shortTermQ: {
          type: "question",
          saveTo: "$preferences.planType.shortterm",
          input: "slider",
          text: "Short Term",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Interested",
            mid: "Somewhat Interested",
            high: "Strongly Prefer",
          },
          next: "otherPreferences",
        },
        otherPreferences: {
          type: "text",
          text: "How important are each of these to you?",
          next: "lowPremiumQ",
          dataTestId: "otherPreferences-text",
        },
        lowPremiumQ: {
          type: "question",
          saveTo: "$preferences.lowPremium",
          input: "slider",
          text: "Low Premium",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important",
          },
          next: "lowDeductibleQ",
        },
        lowDeductibleQ: {
          type: "question",
          saveTo: "$preferences.lowDeductible",
          input: "slider",
          text: "Low Deductible",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important",
          },
          next: "comprehensiveCoverageQ",
        },
        comprehensiveCoverageQ: {
          type: "question",
          saveTo: "$preferences.comprehensiveCoverage",
          input: "slider",
          text: "Comprehensive Coverage",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important",
          },
          next: "networkSizeQ",
        },
        networkSizeQ: {
          type: "question",
          saveTo: "$preferences.networkSize",
          input: "slider",
          text: "Network Size",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important",
          },
          next: "mentalHealthQ",
        },
        mentalHealthQ: {
          type: "question",
          saveTo: "$preferences.mentalHealth",
          input: "slider",
          text: "Mental Health Coverage",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important",
          },
          next: "dentalQ",
        },
        dentalQ: {
          type: "question",
          saveTo: "$preferences.dental",
          input: "slider",
          text: "Dental Coverage",
          min: 0,
          max: 1,
          default: 0.5,
          step: 0.1,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important",
          },
          next: "visionQ",
        },
        visionQ: {
          type: "question",
          saveTo: "$preferences.vision",
          input: "slider",
          text: "Vision Coverage",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important",
          },
          next: "scorePlans",
        },
        scorePlans: {
          type: "action",
          async: true,
          action: async () => {
            // call score plans
            assistant.reScore({
              fetchPolicy: "network-only",
            });
          },
          next: "pcpUsage",
        },
        pcpUsage: {
          type: "text",
          text: "About how many times in the past year did someone on your plan visit your Primary Care Physician?",
          next: "pcpUsageQ",
          dataTestId: "pcpUsage-text",
        },
        pcpUsageQ: {
          type: "question",
          input: "number",
          saveTo: "preferences.pcpUsage",
          next: "specialistUsage",
          dataTestId: "pcpUsageQ-number-field",
        },
        specialistUsage: {
          type: "text",
          text: "About how many times in the past year did someone on your plan visit a specialist?",
          next: "specialistUsageQ",
          dataTestId: "specialistUsage-text",
        },
        specialistUsageQ: {
          type: "question",
          input: "number",
          saveTo: "preferences.specialistUsage",
          next: "prescriptionUsage",
          dataTestId: "specialistUsageQ-number-field",
        },
        prescriptionUsage: {
          type: "text",
          text: "About how many prescription drugs does your household take??",
          next: "prescriptionUsageQ",
          dataTestId: "prescriptionUsage-text",
        },
        prescriptionUsageQ: {
          type: "question",
          input: "number",
          saveTo: "preferences.prescriptionUsage",
          next: "AllNeededDoneInfo",
          dataTestId: "prescriptionUsageQ-number-field",
        },
        AllNeededDoneInfo: {
          type: "text",
          text: "That’s all I need for now!",
          next: "goToPolicies",
          dataTestId: "AllNeededDoneInfo-text",
        },
        goToPolicies: {
          type: "question",
          saveTo: "goToPolicies",
          input: "buttons",
          options: [
            {
              value: true,
              text: "See plans",
              dataTestId: "option-goToPolicies",
            },
          ],
          next: "redirectToPolicies",
        },
        redirectToPolicies: {
          type: "redirect",
          url: "/jamie/recommended-plans",
        },
      },
    },
  },
  step: async (section: string, step: string, progress: boolean = false) => {
    if (!section) section = assistant.variables.section;
    let current: any = assistant.conversation[section];
    if (!step || !Object.keys(current.steps).includes(step))
      step = current.start;
    console.log(`step, ${section} - ${step}`);
    if (progress) {
      const nextStep = current.steps[step].next;
      console.log(`progress to ${nextStep}`);
      if (nextStep === undefined) {
        //go to next section
        section = current.next;
        // if no more sections return undefined
        if (section === undefined) {
          return undefined;
        }
        current = assistant.conversation[section];
        step = current.start;
      } else {
        step = nextStep;
      }
    }
    if (step === "STOP") {
      return;
    }
    const currentStep = current.steps[step];
    mixpanel.track(`TA.${section}.${step}`);
    Sentry.setContext("TransitionAssistant", {
      section,
      step,
    });
    // handle look ups and branching internally
    if (currentStep.type === "action") {
      if (currentStep.async) {
        currentStep
          .action()
          .then(() => {})
          .catch((err: Error) => {
            console.error(err);
          });
      } else {
        try {
          await currentStep.action();
        } catch (err) {
          console.error(err);
        }
      }
      return await assistant.step(section, step, true);
    } else if (currentStep.type === "lookup") {
      //handle a lookup
      if (currentStep.async) {
        const saveTo = currentStep.saveTo;
        currentStep
          .lookup()
          .then(async (result: any) => {
            console.log(`lookup results`, result);
            // save results for future lookup
            // assistant.variables[currentStep.saveTo] = result;
            await assistant.updateDoc(saveTo, result);
          })
          .catch((err: any) => {
            console.error("lookup async error", err);
          });
        return await assistant.step(section, step, true);
      } else {
        assistant.setLookupLoading(true);
        const result = await currentStep.lookup();
        console.log(`lookup results`, result);
        // save results for future lookup
        // assistant.variables[currentStep.saveTo] = result;
        try {
          await assistant.updateDoc(currentStep.saveTo, result);
          assistant.setLookupLoading(false);
          return await assistant.step(section, step, true);
        } catch (err) {
          console.error("lookup error", err);
          assistant.setLookupLoading(false);
        }
      }
    } else if (currentStep.type === "if") {
      let variable;
      // handle an if branch
      if (typeof currentStep.variable === "function") {
        variable = currentStep.variable();
      } else {
        variable = assistant.lookup.answers.get(currentStep.variable);
      }
      console.log(
        `evaluating ${variable} ${currentStep.check} ${currentStep.value}`
      );
      // eslint-disable-next-line no-eval
      const check = eval(`variable ${currentStep.check} currentStep.value`);
      console.log(`evaluated ${check}`);
      let nextStep = "";
      if (check) {
        console.log(`proceed to ${currentStep.ifTrue}`);
        nextStep = currentStep.ifTrue;
      } else {
        console.log(`proceed to ${currentStep.ifFalse}`);
        nextStep = currentStep.ifFalse;
      }
      if (nextStep === undefined) {
        section = current.next;
        if (section === undefined) {
          return undefined;
        }
      }
      return await assistant.step(section, nextStep);
      // const check = eval(`${assistant.variables[currentStep.variable]} ${currentStep.check} ${currentStep.value}`);
      // console.log(`evaluating ${currentStep.variable}: ${assistant.variables[currentStep.variable]} ${currentStep.check} ${currentStep.value}`, check);
      // if (check) {
      //   console.log(`proceed to ${currentStep.ifTrue}`);
      //   return assistant.step(section, currentStep.ifTrue);
      // } else {
      //   console.log(`proceed to ${currentStep.ifFalse}`);
      //   return assistant.step(section, currentStep.ifFalse);
      // }
    } else if (currentStep.type === "question") {
      if (currentStep.options && typeof currentStep.options === "string") {
        // do a lookup
        currentStep.options = assistant.lookup.answers.get(currentStep.options);
      }
    } else if (currentStep.type === "redirect") {
      assistant.navigate(currentStep.url);
    } else if (currentStep.type === "gotoSection") {
      return await assistant.step(currentStep.section, currentStep.step);
    }
    let final = { ...currentStep };
    if (currentStep.default && typeof currentStep.default === "function") {
      final.default = await currentStep.default(assistant);
    }
    if (currentStep.text && typeof currentStep.text === "function") {
      final.text = await currentStep.text(assistant);
    }
    return {
      section,
      step,
      helper:
        current.steps[step].helper ||
        current.helper ||
        function () {
          return <></>;
        },
      response: final,
    };
  },
  updateDoc: undefined,
  navigate: undefined,
  setLookupLoading: undefined,
  remoteConfig: {},
  reScore: undefined,
};

function TransitionAssistant() {
  const { settings } = useSettings();
  const { firestore } = useFirebase();
  const quotit = useRemoteConfig('quotit').asBoolean();
  assistant.remoteConfig.quotit = quotit;
  const jamiePlanPreviewTable = useRemoteConfig('jamiePlanPreviewTable').asBoolean();
  assistant.remoteConfig.jamiePlanPreviewTable = jamiePlanPreviewTable;
  const auth = useRef(useAuth());
  const { user } = useAuth();
  const [error, setError] = useState<string>("");
  const [lookupLoading, setLookupLoading] = useState<boolean>(false);
  assistant.setLookupLoading = setLookupLoading;
  const { modalDispatch } = useContext(ModalContext);
  assistant.modalDispatch = modalDispatch;
  const [helpfulInfoAnchorEl, setHelpfulInfoAnchorEl] = useState<null | HTMLElement>(null);
  const helpfulInfoOpen = Boolean(helpfulInfoAnchorEl);
  const handleClickHelpfulInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHelpfulInfoAnchorEl(event.currentTarget);
  };
  const handleCloseHelpfulInfo = () => {
    setHelpfulInfoAnchorEl(null);
  };
  const [started, setStarted] = useState<boolean>(false);
  const [contactSubmitted, setContactSubmitted] = useState<boolean>(false);
  const apollo = useApolloClient();
  const [section, setSection] = useState<string>("");
  const [step, setStep] = useState<string>("");
  const [currentMessage, setCurrentMessage] = useState<{ response: Message, helper: any, section: string, step: string }>();
  const [messageThread, setMessageThread] = useState<Message[]>([]);
  const navigate = useNavigate();
  const basePath: string = settings.REACT_APP_WHEN_URL;
  const ref = useChatScroll(messageThread);
  const userDocRef = user ? doc(firestore, 'users', user?.uid!) : null;
  const [userDoc, userDocLoading, userDocError] = useDocument(
    userDocRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const userAnswersDoc = user ? doc(firestore, 'users', user?.uid!, "answers", user?.uid!) : null;
  const [answers, answersLoading, firestoreError] = useDocument(
    userAnswersDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [currentUserSnapshot] = useDocument(
    user ? doc(firestore, 'users', user.uid) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const currentUserData = currentUserSnapshot?.data();
  assistant.currentUser = currentUserData;
  const userId = currentUserData?.uid;
  const syncEmployeeId = currentUserData?.syncEmployeeId;
  const [cobraPlanSnapshot] = useDocument(
    userId && syncEmployeeId ? doc(firestore, 'users', userId, "formerEmployments", syncEmployeeId) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  assistant.cobraPlans = cobraPlanSnapshot?.data()?.cobraPlans;
  const [insuranceSnapshot] = useDocument(
    userId ? doc(firestore, "users", userId, "settings", "insurance") : null
  );
  if (insuranceSnapshot && insuranceSnapshot.data()?.cobra?.planComparison) {
    assistant.planComparison = insuranceSnapshot.data()?.cobra?.planComparison;
  }

  const [plans] = useCollection(
    query(
      collection(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans`),
      orderBy("insuranceType"),
      orderBy("recommendation.internalScore", "desc"),
      where("insuranceType", "!=", "ShortTerm"),
      limit(3)
    )
  );

  assistant.plans = [];
  plans?.docs.map((plan) => {
    return assistant.plans.push(plan.data())
  })

  const [shortTermPlan] = useCollection(
    query(
      collection(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans`),
      orderBy("insuranceType"),
      orderBy("recommendation.internalScore", "desc"),
      where("insuranceType", "==", "ShortTerm"),
      limit(1)
    )
  );

  assistant.shortTermPlan = []
  shortTermPlan?.docs.map((plan) => {
    return assistant.shortTermPlan.push(plan.data())
  })

  const [caseSnapshot] = useDocument(
    userId && syncEmployeeId ? doc(firestore, 'companies', currentUserData.companyId, "cases", syncEmployeeId) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  assistant.case = caseSnapshot?.data()

  const [whenBenefitSnapshot] = useDocument(
    userId && syncEmployeeId ? doc(firestore, 'users', userId, "whenBenefits", syncEmployeeId) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  assistant.whenBenefit = whenBenefitSnapshot?.data()

  assistant.updateDoc = async (variable: string, value: any) => {
    if (userAnswersDoc) {
      try {
        console.log('assistant updateDoc', variable, value)
        if (variable[0] === "$") {
          // this saves into a subcollection
          // parse $subcollection out of $subcollection.document.field
          const [subcollectionIndicator, ...field] = variable.split(".");
          const subcollection = subcollectionIndicator.slice(1);
          console.log("save to subcollection", subcollection, "field", field.join("."))
          if (subcollection === "answers") {
            await updateDoc(userAnswersDoc, {
              [field.join(".")]: value
            })
          } else {
            const subCollectionRef = doc(firestore, 'users', user?.uid!, "answers", subcollection);
            try {
              await updateDoc(subCollectionRef, {
                [field.join(".")]: value
              })
            } catch (err) {
              await setDoc(subCollectionRef, { uid: user?.uid! });
              await updateDoc(subCollectionRef, {
                [field.join(".")]: value
              })
            }
          }
        } else {
          await updateDoc(userAnswersDoc, {
            [variable]: value
          });
        }

      } catch (err) {
        console.error("assistant.updateDoc error", err);
        setError("Failed to save to the database");
        throw err;
      }
      // console.log('assistant reloadAnswers')
      // await reloadAnswers();
      // console.log(JSON.stringify(answers?.data()));
    }
  }
  assistant.lookup.answers = answers;
  assistant.lookup.auth = auth.current;
  assistant.lookup.firebase = auth;
  assistant.lookup.apollo = apollo;
  assistant.navigate = navigate;
  const [reScorePlans] = useLazyQuery<{ total: number }, { effectiveDate: string }>(Queries.reScorePlans, {
    notifyOnNetworkStatusChange: true,
  });
  assistant.reScore = reScorePlans;
  let writeMessage = async (message: Message) => {
    console.log("writeMessage", message)
    setMessageThread(
      [
        ...messageThread,
        message
      ]
    );
  };
  let doneWriting = async (value: any) => {
    console.log(`doneWriting ${currentMessage?.section} - ${currentMessage?.step}`)
    if (currentMessage?.response?.aria !== undefined && currentMessage?.response?.aria.label === "enterCobraPremium") {
      value = Number(value)
    }
    if (currentMessage?.response?.aria !== undefined && currentMessage?.response?.aria.label === "cobraPlan") {
      if (value === "none") {
        assistant.planComparison = []
      } else {
        const selectedPlan = assistant.cobraPlans.find((plan: any) => plan.name === value);
        if (selectedPlan) {
          assistant.planComparison = selectedPlan;
        }
      }
      await setDoc(
        doc(firestore, "users", userId, "settings", "insurance"),
        { cobra: { ...insuranceSnapshot?.data()?.cobra, planComparison: assistant.planComparison } },
        { merge: true }
      );
    }
    // if current message is a question validate and save the answer
    if (currentMessage?.response.type === "question" && currentMessage.response.saveTo !== undefined) {
      console.log(`save ${value} into ${currentMessage.response.saveTo}`)
      if (currentMessage.response.saveTo instanceof Function) {
        //custom saveTo function
        try {
          await currentMessage.response.saveTo(value);
        } catch (err) {
          console.error("doneWriting saveTo error", err);
        }
      } else {
        try {
          await assistant.updateDoc(currentMessage.response.saveTo, value);
          if (currentMessage.response.aria?.label === "SELF-DOB") {
            await assistant.updateDoc("personalInfo.dateOfBirth", value)
          }
        } catch (err) {
          console.error("doneWriting updateDoc error", err);
        }
        // await reloadAnswers();
      }
      console.log('done saving')
    }
    //figure out what to do next
    try {
      const result = await assistant.step(section, step, true);
      if (result !== undefined) {
        setCurrentMessage(result);
        console.log(`stepped into message ${result}`)
        setSection(result.section);
        setStep(result.step);
        writeMessage({ ...result.response, id: messageThread.length.toString() });
      }
    } catch (err) {
      console.error("doneWriting step error", err);
    }
  };
  useEffect(() => {
    //check Cookies for employerLanding and set mixpanel
    if (Cookies.get("employerLanding")) {
      console.log("set employer", Cookies.get("employerLanding"));
      mixpanel.people.set("employer", Cookies.get("employerLanding"));
    }
  }, []);
  useEffect(() => {
    if (firestoreError)
      console.error(`firestoreError error`, firestoreError);
    if (userDocError)
      console.error(`userDocError error`, userDocError);

    if (user && !answersLoading && !userDocLoading && userAnswersDoc && userDocRef) {
      //check if firestore user answers doesn't exist
      if (!userDoc?.exists()) {
        setDoc(userDocRef, {}).then(() => {
          if (!answers?.exists()) {
            console.log("create answers")
            setDoc(userAnswersDoc, {}).then(() => {
              console.log("user answers created");
            }).catch((err) => {
              setError("Error connecting to database");

            });
          }
        });
      } else
        if (!answers?.exists()) {
          console.log("create answers")
          setDoc(userAnswersDoc, {}).then(() => {
            console.log("user answers created");
          }).catch((err) => {
            setError("Error connecting to database");

          });
        }
    }
  })
  useEffect(() => {
    if (currentMessage === undefined && !started && user && answers?.exists()) {
      setStarted(true);
      // check query params if section is set
      const urlParams = new URLSearchParams(window.location.search);
      const sectionParam = urlParams.get('section');
      if (sectionParam) {
        assistant.variables.section = sectionParam;
      }
      console.log("Start assistant");
      assistant.step().then((result: any) => {
        if (result !== undefined) {
          setCurrentMessage(result);
          console.log(`stepped into message ${result}`);
          setSection(result.section);
          setStep(result.step);
          writeMessage({ ...result.response, id: messageThread.length.toString() });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [started, writeMessage, messageThread.length])

  const [members, setMembers] = useState()
  useEffect(() => {
    if (!answersLoading || !firestoreError) {
      setMembers(answers?.data()?.insuranceDetails?.household?.members)
    }
  }, [answers, answersLoading, firestoreError])
  const addActivity = useCallback(async (user: any) => {
    const userActivityCollectionRef = collection(firestore, `users/${user?.uid}/activity`);
    await addDoc(userActivityCollectionRef, {
      activity: UserActivityTypes.USEDJAMIE,
      type: "user",
      userId: user?.uid,
      timestamp: new Date(),
    });
  }, [firestore]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await user?.getIdToken(true);
        await addActivity(user);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user, addActivity]);

  useEffect(() => {
    const updateEffectiveDate = async () => {
      const effectiveDate = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
      await updateDoc(doc(firestore, `users/${user?.uid}/answers/${user?.uid}`), {
        "insuranceDetails.effectiveDate": effectiveDate
      });
    }
    if (answers && answers?.get("insuranceDetails.effectiveDate") && moment(answers?.get("insuranceDetails.effectiveDate")) < moment().endOf('month')) {
      // effective date is in the past make it the next month
      updateEffectiveDate();
    }
    if (answers && !answers?.get("insuranceDetails.effectiveDate")) {
      // effective date is not set at all
      updateEffectiveDate();
    }
  }, [answers, user, firestore]);

  useEffect(() => {
    if (lookupLoading) {
      autoScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupLoading])
  if (answersLoading || !user) {
    return (
      <Box>Loading</Box>
    );
  }
  function autoScroll() {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }
  return (
    <Box>
      <Box sx={{
        py: 4
      }}>

        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={7}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4
              }}>
                <Typography data-testid="jamie-intro1" color={"primary"} variant="h1">Meet <Box component={"span"} fontWeight={600}>{assistant.settings.name}</Box> - Your Health Insurance Advocate</Typography>
                <Typography data-testid="jamie-intro2">{assistant.settings.name} has been specially crafted to help you navigate healthcare with a personal touch. {assistant.settings.name} is able to take the information you provide and analyze it to help you find the right healthcare solution for <Box component={"span"} color={"primary"} fontWeight={600}>you</Box>.</Typography>
                <Typography data-testid="jamie-intro3" color={"primary"} variant="h2">We&apos;ll let {assistant.settings.name} take it from here.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} sx={{
              maxHeight: "400px",
              display: { xs: "none", sm: "block" }
            }}>
              <JamieImage height="100%" width="100%" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{
        py: 4
      }}
        id="jamie"
      >
        <Container>
          <Box sx={{
            backgroundColor: "white.main",
            borderRadius: "12px"
          }}>
            <Box sx={{
              backgroundColor: "beige.dark",
              borderRadius: "12px 12px 0px 0px",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              padding: "19px 16px",
              gap: "10px"
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "21px",
                ml: { xs: "0px", sm: "54px" }
              }}>
                <JamieImage width="40px" height="40px"></JamieImage>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "3px"
                }}>
                  <Typography color={"primary"} >Jamie, Health Insurance Advocate</Typography>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                  }}>
                    <Box sx={{
                      backgroundColor: "#26BE00",
                      borderRadius: "100%",
                      width: "8px",
                      height: "8px"
                    }}></Box>
                    <Typography fontSize="11px">Online</Typography>
                  </Box>
                </Box>
              </Box>
              <Button onClick={handleClickHelpfulInfo} disableRipple sx={{
                backgroundColor: "primary.main",
                borderRadius: "12px",
                padding: "6px 20px",
                display: "flex",
                maxWidth: "277px",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "primary.main",

                }
              }}>
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
                  data-testid="helpfulInfoMenuButton"
                >
                  <InfoIcon width="16px"></InfoIcon>
                  <Typography color={"white.main"} sx={{ pl: "10px" }}>Helpful <Box component={"span"} fontWeight={600}>Information</Box></Typography>
                </Box>
                <KeyboardArrowDown color="secondary"></KeyboardArrowDown>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={helpfulInfoAnchorEl}
                open={helpfulInfoOpen}
                onClose={handleCloseHelpfulInfo}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                  sx: { py: 0 }
                }}
                TransitionComponent={Fade}
                PaperProps={{
                  sx: {
                    borderRadius: "0px 0px 12px 12px",
                    mt: "-10px"
                  }
                }}
                data-testid="helpfulInfoMenu"
              >
                <Box sx={{
                  backgroundColor: "primary.main",
                  px: "20px",
                  py: "24px",
                  maxWidth: "277px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2
                }}>
                  {currentMessage?.helper()}
                  <Typography color="white.main" data-testid="helpfulInfoMessage" >
                    If you are worried about sharing your information with {assistant.settings.name} please
                    <Link
                      href={`${basePath}/privacy`}
                      style={{ fontWeight: "bold" }}
                      color="white.main"
                    >
                      review our privacy policy
                    </Link>
                  </Typography>
                </Box>
              </Menu>
            </Box>
            <Box>
              <Box sx={{

                height: "65vh",
                maxHeight: "65vh",
                overflowY: "scroll",
                display: "flex",
                flexDirection: "column",
                gap: "14px",
                px: { xs: "12px", md: "140px" },
                py: "20px"
              }} ref={ref}>
                <Typography fontSize={"11px"}>Jamie</Typography>
                {messageThread.map((message, index) => {
                  if (message.type === "question") {
                    // console.log("output a question")
                    return (
                      <Box key={message.id}>
                        <QuestionMessage message={message} speed={assistant.settings.typeSpeed} finished={doneWriting} members={members} />
                      </Box>
                    );
                  } else if (message.type === "text") {
                    return (
                      <Box key={message.id}>
                        <TypedMessage autoScroll={autoScroll} className={`${index === messageThread.length ? "last-line" : ""}`} text={message.text} speed={assistant.settings.typeSpeed} finished={doneWriting} testId={message.dataTestId || ""} />
                      </Box>
                    );
                  } else if (message.type === "planOptionTable") {
                    const effectiveDate = answers?.get("insuranceDetails.effectiveDate")
                    return (
                      <Box key={message.id}>
                        <PlanOptionsJamie effectiveDate={effectiveDate} autoScroll={autoScroll} speed={assistant.settings.typeSpeed} finished={doneWriting} cobraEstimate={assistant.lookup.answers.get("cobra.estimate") || 0} />
                      </Box>
                    )
                  }
                  else {
                    return (
                      <Box key={message.id}>

                      </Box>
                    )
                  }
                })
                }
                {lookupLoading && <Box sx={{
                  display: "flex",
                  justifyContent: "start"
                }}>
                  <Box sx={{
                    padding: 1,
                    backgroundColor: "gray.light",
                    borderRadius: "17px 17px 17px 2px",
                    maxWidth: { xs: "100%", sm: "50%" }
                  }}>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 2,
                        "& > div": {
                          width: "10px",
                          height: "10px",
                          marginX: 0.5,
                          borderRadius: "50%",
                          backgroundColor: "gray.main",
                          opacity: 1,
                          animation: "bouncing-loader 0.6s infinite alternate",
                          "&:nth-child(2)": {
                            animationDelay: "0.2s",
                          },
                          "&:nth-child(3)": {
                            animationDelay: "0.4s",
                          },
                        },
                        "@keyframes bouncing-loader": {
                          to: {
                            opacity: 0.1,
                            transform: "translateY(-16px)",
                          },
                        },
                      }}
                    >
                      <Box />
                      <Box />
                      <Box />
                    </Box>
                  </Box>
                </Box>
                }
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Dialog open={error !== ""} onClose={() => { setError("") }}>
        <Box sx={{
          p: 4
        }}>
          <Typography color="error">Error: {error}</Typography>
          <Button onClick={() => { setError("") }}>Close</Button>
        </Box>
      </Dialog>
      <Snackbar
        open={contactSubmitted}
        autoHideDuration={6000}
        onClose={() => { setContactSubmitted(false) }}
        message="Thank you for contacting us, someone will reach out soon."
      />
    </Box >
  );
}


function TypedMessage({ text, speed, finished, className, autoScroll, testId }: { text: string, speed: number, finished: Function, className?: string, autoScroll?: Function, testId?: string }) {
  const [message, setMessage] = useState<string>(text[0]);
  const [interval, setNewInterval] = useState<any>();
  const [complete, setComplete] = useState<boolean>(false);
  const ref = useRef<string>("");
  ref.current = message;
  const scrollSpan = useRef<any>();

  useEffect(() => {
    if (complete) {
      setMessage(text);
    }
    if (!interval) {
      let newInterval = setInterval(() => {
        // console.log(`currently ${message} ref ${ref.current}`)
        if (ref.current.length < text.length)
          setMessage(ref.current + text[ref.current.length])
        if (ref.current.length >= text.length) {
          setComplete(true);
          finished();
          clearInterval(newInterval);
        }
      }, speed);
      setNewInterval(newInterval);
    }
  }, [complete, finished, interval, message, speed, text])
  useEffect(() => {
    if (scrollSpan.current) {
      //overflowDiv.current.scrollTop = 
      if (typeof autoScroll === "function") {
        autoScroll();
      }
    }
    //scrollSpan.current.scrollIntoView({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);
  return (
    <Box>
      <Typography
        data-testid={testId || ""}
        className={className}
        sx={{
          padding: "10px 15px",
          backgroundColor: "#F1F1F1",
          borderRadius: "17px 17px 17px 2px",
          width: "fit-content",
          maxWidth: { xs: "100%", sm: "50%" },
          whiteSpace: 'pre-wrap', // Ensure line breaks are preserved
          wordBreak: 'break-word', // Handle long words
        }}
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }} // Render HTML content
      />
      <span ref={scrollSpan}></span>
    </Box>
  );
}

function QuestionMessage({ message, speed, finished, members }: { message: Message, speed: number, finished: Function, members?: Object }) {
  const [answer, setAnswer] = useState<string | number>(message.default || "");
  const [finalText, setFinalText] = useState<string | number>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [validationText, setValidationText] = useState<string>("");
  function validate(value: string | number) {
    if (typeof value === "string") {
      if (message.input === "date") {
        if (message?.aria?.label === "SELF-DOB") {
          const currentDate = new Date();
          if (currentDate < new Date(value)) {
            return false
          }
        }
        return value.match("^[0-9]{2}/[0-9]{2}/[0-9]{4}$");
      } else if (typeof message.validation === "string") {
        return value.match(message.validation);
      } else if (typeof message.validation === "function") {
        return message.validation(value);
      }
    }
    return true;
  }
  function clickButton(value: any) {
    if (validate(value)) {
      console.log(`button clicked ${value}`)
      finished(value);
      setDisabled(true);
      setValidationText("");
      message.options?.forEach((option => {
        if (option.value === value) {
          setFinalText(option.text);
        }
      }))
    } else {
      if (message.input === "date") {
        setValidationText("Please provide a date with the format MM/DD/YYYY");
      } else {
        setValidationText("Please provide a valid value");
      }
    }
  }
  function submit() {
    try {
      setLoading(true)
      if (validate(answer)) {
        finished(answer);
        setDisabled(true);
        setValidationText("");
        if (message.options) {
          message.options?.forEach((option => {
            if (option.value === answer) {
              setFinalText(option.text);
            }
          }))
        } else {
          if (message.input === "password" && typeof answer === "string") {
            setFinalText("*".repeat(answer.length));
          } else {
            setFinalText(answer);
          }
        }
        if (message?.aria?.label === "enterCobraPremium") {
          setFinalText(`$${answer}`)
        }
      } else {
        if (message.input === "date") {
          setValidationText("Please provide a date with the format MM/DD/YYYY");
        } else {
          setValidationText("Please provide a valid value");
        }
      }
    } catch (error) {
      //handle error
    } finally {
      setLoading(false)
    }
  }
  if (loading) {
    return (
      <>
        <Box sx={{
          display: "flex",
          justifyContent: "start"
        }}>
          <Box sx={{
            padding: 1,
            backgroundColor: "gray.light",
            borderRadius: "17px 17px 17px 2px",
            maxWidth: { xs: "100%", sm: "50%" }
          }}>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
                "& > div": {
                  width: "10px",
                  height: "10px",
                  marginX: 0.5,
                  borderRadius: "50%",
                  backgroundColor: "gray.main",
                  opacity: 1,
                  animation: "bouncing-loader 0.6s infinite alternate",
                  "&:nth-child(2)": {
                    animationDelay: "0.2s",
                  },
                  "&:nth-child(3)": {
                    animationDelay: "0.4s",
                  },
                },
                "@keyframes bouncing-loader": {
                  to: {
                    opacity: 0.1,
                    transform: "translateY(-16px)",
                  },
                },
              }}
            >
              <Box />
              <Box />
              <Box />
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  if (finalText !== "" && message.input !== 'slider') {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "end"
      }}>
        <Box sx={{
          padding: "10px 15px",
          backgroundColor: "primary.main",
          borderRadius: "17px 17px 2px 17px",
          maxWidth: { xs: "100%", sm: "50%" }
        }}>
          <Typography data-testid={message?.dataTestId} color="white.main">{message.aria?.label === 'Phone Number' ? formatPhoneNumber(finalText) : finalText}</Typography>
        </Box>
      </Box>
    );
  }
  if (message.input === 'select' && message.options) {
    if (message?.aria?.label === "cobraPlan" && assistant.cobraPlans) {
      let options = []
      assistant.cobraPlans.map((plan: any) => {
        return options.push({ text: plan.name, value: plan.name })
      })
      options.push({ text: "I was not enrolled in a health insurance plan from my employer", value: "none" })
      message.options = options
    }
    if (message.aria?.label === "memberRelationship") {
      let spouseCount = 0;
      const words = message.text.split(" ")
      const name = words[2]
      if (members) {
        Object.values(members).forEach((member: any) => {
          if (member?.relationship === "spouse" && member.name !== name) {
            spouseCount++;
          }
        });

        if (spouseCount >= 1) {
          message.options = message.options.filter((option) => option.value !== "spouse");
        }
      }
    }
    if (!answer) {
      //default to first answer
      setAnswer(message.options[0].value);
    }
    return (
      <Box key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "5px",
        maxWidth: { xs: "100%", sm: "50%" }
      }}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">{message.text}</InputLabel>
          <Select disabled={disabled} label={message.text} value={answer} onChange={(e) => { setAnswer(e.target.value) }} sx={{ width: "100%" }} data-testId="selectMenu">
            {message.options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value} data-testid={option.text}>{option.text}</MenuItem>
              )
            })
            }
          </Select>
        </FormControl>
        <Button data-testId="selectSaveButton" aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
      </Box>
    );
  } else if (message.input === "password") {
    return (
      <Box key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
        display: "flex",
        flexDirection: "row",
        maxWidth: { xs: "100%", sm: "50%" },
        gap: "5px"
      }}>
        <TextField data-testid={message?.dataTestId} autoComplete="new-password" type="password" label={message.text} disabled={disabled} value={answer} sx={{ width: "100%" }} onChange={(e) => { setAnswer(e.target.value) }} />
        <Button aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
      </Box>
    );
  } else if (message.input === "date") {
    return (
      <>
        <Box data-testid={message?.dataTestId} key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
          display: "flex",
          flexDirection: "row",
          width: { xs: "100%", sm: "50%" },
          gap: "5px"
        }}>
          <DesktopDatePicker
            label={message.text}
            maxDate={message.aria?.label === "DOB" ? moment() : undefined}
            format="MM/DD/YYYY"
            onChange={(value) => {
              if (value)
                setAnswer(value.format("MM/DD/YYYY"))
            }}
            onError={(error) => {
              if (error) {
                setValidationText("Please provide a date with the following format MM/DD/YYYY");
              } else {
                setValidationText("")
              }
            }}
            disabled={disabled}
            value={answer !== "" ? moment(answer, "MM/DD/YYYY") : null}
          ></DesktopDatePicker>
          <Button data-testid={`${message?.dataTestId}-btn`} aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
        </Box>
        {validationText && <Typography color="error">{validationText}</Typography>}
      </>
    );
  } else if (message.input === 'buttons' && message.options) {
    return (
      <Box key={message.id} sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "end",
        gap: "5px"
      }}>
        {message.options.map((option, index) => {
          return (
            <Button data-testid={message?.dataTestId === "country" ? `${message.dataTestId}-${index}` : option?.dataTestId} aria-label={option.text} key={message?.dataTestId === "country" ? index : option.value} type="submit" variant={option.variant || "outlined"} color={option.color} disabled={disabled} onClick={() => { clickButton(option.value); }}>{option.text}</Button>
          );
        })}
      </Box>
    );
  } else if (message.input === 'slider') {
    return (
      <Box key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "end",
        alignItems: "center",
        gap: 4
      }}>
        <Typography fontWeight={600} color={"primary"}>{message.text}</Typography>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}>
            <Typography>{message?.headers?.low}</Typography>
            <Typography>{message?.headers?.mid}</Typography>
            <Typography>{message?.headers?.high}</Typography>
          </Box>
          <Slider
            aria-label="ppo plan type"
            disabled={disabled}
            defaultValue={0.5}
            value={typeof answer === "number" ? answer : 0.5}
            onChange={(e, v) => {
              if (typeof v === "number") {
                setAnswer(v)
              } else {
                //do nothing
              }
            }}
            step={message.step || 0.1}
            marks
            min={message.min || 0}
            max={message.max || 1}
          />
        </Box>
        <Button data-testid={`${message?.text}-btn`} aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
      </Box>
    );
  } else {
    console.log("message", message)
    return (
      <Box key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        gap: "5px"
      }}>
        <InputLabel sx={{ display: "none" }} hidden={true} aria-hidden={false} htmlFor={`input-${message.id}`}>{message.aria?.label}</InputLabel>
        {message.aria?.label === 'Phone Number' ? (
          <MuiPhoneNumber
            data-testid={message?.dataTestId}
            aria-label={message.aria?.label}
            id={`input-${message.id}`}
            autoComplete={message.autoComplete}
            error={validationText !== ""}
            helperText={validationText}
            type={message.input || ""}
            label={message.text}
            disabled={disabled}
            value={answer}
            autoFormat={false}
            sx={{
              xs: '100%', sm: '50%'
            }}
            onChange={(value) => {
              if (typeof value === "string" || typeof value === "number") setAnswer(value);
            }}
            variant="outlined"
            onlyCountries={['us']}
            defaultCountry={'us'}
            disableAreaCodes={true}
            countryCodeEditable={false}
          />
        ) :
          <TextField data-testid={message?.dataTestId} aria-label={message.aria?.label} id={`input-${message.id}`} autoComplete={message.autoComplete} error={validationText !== ""} helperText={validationText} type={message.input || ""} label={message.text} disabled={disabled} value={answer} sx={{
            xs: '100%', sm: '50%'
          }} onChange={(e) => { setAnswer(e.target.value) }} />
        }
        <Button data-testid={`${message?.dataTestId}-btn`} aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
      </Box>
    );
  }
}


function useChatScroll<T>(dep: T): React.MutableRefObject<HTMLDivElement | undefined> {
  const ref = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (ref.current) {
      // ref.current.scroll({ top: ref.current.scrollTop, behavior: "smooth" })
      ref.current.scrollTop = ref.current.scrollHeight + ref.current.offsetHeight;
    }
  }, [dep]);
  return ref;
}

export default Sentry.withProfiler(TransitionAssistant);