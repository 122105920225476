import {
  Dialog,
  Box,
  Typography,
  TextField,
  Button,
  DialogContent,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { Mutations } from "services/GraphQL/Mutations";
import { useNotifications } from "contexts/Notification";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import { useAuth } from "contexts/User";

interface IAdminCreateUserDialogInput {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  zipCode: string;
}

interface INewUserProps {
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
  zipCode?: string;
}

const readOnlyFieldStyle = {
  "&:has([readonly]) ": {
    "& .MuiInputLabel-outlined": {
      color: "#cecece",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#cecece",
    },
    "& .MuiInputBase-input": {
      color: "#999999",
    },
  },
};

function AdminCreateUserDialog({
  open,
  onClose,
  companyId,
  caseId,
  newUserProps,
}: {
  open: boolean;
  onClose: () => void;
  companyId: string;
  caseId: string;
  newUserProps: INewUserProps;
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IAdminCreateUserDialogInput>();

  const { notifications } = useNotifications();
  const navigate = useNavigate();

  const [adminCreateUser, { loading }] = useMutation(
    Mutations.adminCreateUserFromCase
  );

  const [syncDataForExitCode] = useMutation(
    Mutations.syncDataForExitCode
  );

  const { sendPasswordResetEmail } = useAuth();

  const { firstName, lastName, email, dateOfBirth, zipCode } = newUserProps;

  const onSubmit: SubmitHandler<IAdminCreateUserDialogInput> = async (
    data: IAdminCreateUserDialogInput
  ) => {
    try {
      const response = await adminCreateUser({
        variables: {
          companyId,
          caseId,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          dateOfBirth: data.dateOfBirth,
          zipCode: data.zipCode,
        },
      });

      const newUser = response?.data?.adminCreateUserFromCase;
      await sendPasswordResetEmail(data.email);

      // attempt to sync the user's data to the case
      try {
        const result = await syncDataForExitCode({
          variables: {
            companyId,
            exitCode: caseId,
            lastName: data.lastName,
            birthDate: data.dateOfBirth,
            zipCode: data.zipCode,
            isAdmin: true,
            userUid: newUser?.uid,
          },
        });
        if (!result.data?.syncDataForExitCode) {
          throw new Error("Error syncing user data");
        }
      } catch (error) {
        throw new Error("Error syncing user data");
      }

      notifications.success(`User ${newUser?.email} created successfully`);

      navigate(`/admin/users/${newUser?.uid}`);
    } catch (error) {
      notifications.error(`Error creating user: ${error}`);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      sx={{ "& .MuiPaper-root": { width: { sm: "50%", xs: "70%" } } }}
    >
      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Typography variant="h3">Create User</Typography>
          <TextField
            inputProps={{ readOnly: true }}
            label="First Name"
            {...register("firstName", { required: true })}
            aria-invalid={errors.firstName ? true : false}
            defaultValue={firstName}
            fullWidth
            error={!!errors.firstName}
            helperText={
              errors.firstName?.type === "required"
                ? "First name is required"
                : ""
            }
            sx={readOnlyFieldStyle}
          />
          <TextField
            inputProps={{ readOnly: true }}
            label="Last Name"
            {...register("lastName", { required: true })}
            aria-invalid={errors.lastName ? true : false}
            fullWidth
            defaultValue={lastName}
            error={!!errors.lastName}
            helperText={
              errors.lastName?.type === "required"
                ? "Last name is required"
                : ""
            }
            sx={readOnlyFieldStyle}
          />
          <TextField
            label="Email"
            {...register("email", { required: true })}
            aria-invalid={errors.email ? true : false}
            fullWidth
            error={!!errors.email}
            defaultValue={email}
            helperText={
              errors.email?.type === "required" ? "Email is required" : ""
            }
          />
          <TextField
            inputProps={{ readOnly: true }}
            label="Date of Birth"
            {...register("dateOfBirth", { required: true })}
            aria-invalid={errors.dateOfBirth ? true : false}
            fullWidth
            error={!!errors.dateOfBirth}
            defaultValue={dateOfBirth}
            helperText={
              errors.dateOfBirth?.type === "required"
                ? "Date of birth is required"
                : ""
            }
            sx={readOnlyFieldStyle}
          />
          <TextField
            inputProps={{ readOnly: true }}
            label="Zip Code"
            {...register("zipCode", { required: true })}
            aria-invalid={errors.zipCode ? true : false}
            fullWidth
            error={!!errors.zipCode}
            defaultValue={zipCode}
            helperText={
              errors.zipCode?.type === "required" ? "Zip code is required" : ""
            }
            sx={readOnlyFieldStyle}
          />
          <Typography variant="body2" color="textSecondary">
            Submitting will create a new user synced to this case. The user will
            receive a password reset email to complete registration.
          </Typography>
          <Button type="submit" variant="contained" disabled={loading}>
            Create User
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default AdminCreateUserDialog;
