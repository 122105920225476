import React, { useState } from 'react';
import { Button, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { doc, setDoc, deleteField, Firestore } from "firebase/firestore";
import { User } from 'firebase/auth';

interface ResetInsuranceButtonProps {
  user: User | null;
  firestore: Firestore;
}

const ResetInsuranceButton: React.FC<ResetInsuranceButtonProps> = ({ user, firestore }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const resetInsurance = async () => {
    if (!user?.uid) {
      console.error("User is not authenticated");
      return;
    }

    try {
      await setDoc(doc(firestore, "users", user.uid, "answers", user.uid),
        {
          insuranceDetails: deleteField(),
        }, { merge: true });
      console.log("Insurance details reset successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error resetting insurance details: ", error);
    }
    handleCloseDialog();
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Button 
        onClick={handleOpenDialog}
        variant="contained"
      >
        Reset Insurance
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Insurance Reset"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to reset the household information for this user? 
            <br/>
            This action cannot be undone. 
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={resetInsurance}>
            Confirm Reset
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Insurance details have been reset"
      />
    </>
  );
};

export default ResetInsuranceButton;