import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Slider, Button, Grid, CircularProgress } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import moment from "moment";

type PlanTypePreferences = {
    hmo: number;
    ppo: number;
    shortterm: number;
};

type Preferences = {
    comprehensiveCoverage: number;
    dental: number;
    lowDeductible: number;
    lowPremium: number;
    mentalHealth: number;
    networkSize: number;
    planType: PlanTypePreferences;
    vision: number;
};

const initialPreferences: Preferences = {
    comprehensiveCoverage: 0,
    dental: 0,
    lowDeductible: 0,
    lowPremium: 0,
    mentalHealth: 0,
    networkSize: 0,
    planType: {
        hmo: 0.5,
        ppo: 0.5,
        shortterm: 0.5,
    },
    vision: 0,
};

const preferenceLabels: { [key in keyof Preferences]: string } = {
    comprehensiveCoverage: "Comprehensive Coverage",
    dental: "Dental",
    lowDeductible: "Low Deductible",
    lowPremium: "Low Premium",
    mentalHealth: "Mental Health",
    networkSize: "Network Size",
    planType: "Plan Type",
    vision: "Vision",
};

interface PreferencesTemplateProps {
    sendPrompt: (message: string) => void;
}

const PreferencesTemplate: React.FC<PreferencesTemplateProps> = ({ sendPrompt }) => {
    const { firestore } = useFirebase();
    const { user, userData } = useAuth();
    const [preferences, setPreferences] = useState<Preferences>(initialPreferences);
    const [conversationId] = useState<string | null>(userData?.get("conversationId"));
    const [promptSent, setPromptSent] = useState(true);
    const [loadRates, rateQuery] = useLazyQuery<{ total: number }, { effectiveDate: string }>(Queries.getRates, {
        notifyOnNetworkStatusChange: true,
    });
    const [oldPreferences, setOldPreferences] = useState<Preferences | null>(null);

    useEffect(() => {
        const fetchPreferences = async () => {
            if (user?.uid) {
                const docRef = doc(firestore, `users/${user.uid}/answers/preferences`);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data() as Preferences;
                    console.log("Fetched preferences:", data);
                    setPreferences(data);
                    setOldPreferences(data);
                }
            }
        };
        fetchPreferences();
    }, [firestore, user?.uid]);

    const updatePreferences = useCallback((key: keyof Preferences, value: number) => {
        const newPreferences = { ...preferences, [key]: value };
        console.log("Updating preferences:", newPreferences);
        setPreferences(newPreferences);
        setPromptSent(false);
    }, [preferences]);

    const updatePlanTypePreferences = useCallback((key: keyof PlanTypePreferences, value: number) => {
        const newPlanTypePreferences = { ...preferences.planType, [key]: value };
        const newPreferences = { ...preferences, planType: newPlanTypePreferences };
        console.log("Updating plan type preferences:", newPreferences);
        setPreferences(newPreferences);
        setPromptSent(false);
    }, [preferences]);

    const savePreferencesAndUpdateMessage = useCallback(async () => {
        if (!user?.uid || !conversationId) {
            console.error("User is not authenticated or conversation ID is missing");
            return;
        }

        setPromptSent(true);

        const preferencesRef = doc(firestore, `users/${user.uid}/answers/preferences`);
        await setDoc(preferencesRef, preferences, { merge: true });

        const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
        try {
            const convoSnapshot = await getDoc(convoRef);

            if (convoSnapshot.exists()) {
                const convoData = convoSnapshot.data();
                const messages = convoData.messages || [];

                if (messages.length > 0) {
                    const recentMessage = messages[messages.length - 1];
                    recentMessage.data = {
                        ...recentMessage.data,
                        oldPreferences: oldPreferences,
                        newPreferences: preferences
                    };
                    await updateDoc(convoRef, { messages: messages });
                    console.log("Old and new preferences added to the most recent message data");
                } else {
                    console.error("No messages found in the conversation");
                }
            } else {
                console.error("Conversation document does not exist");
            }
        } catch (error) {
            console.error("Error updating conversation document:", error);
        }

        await loadRates({
            variables: {
                effectiveDate: moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'),
            },
            fetchPolicy: "network-only"
        });

        sendPrompt("I have updated my preferences. Please provide updated health insurance plan recommendations.");
        setOldPreferences(preferences);
    }, [user?.uid, conversationId, firestore, preferences, oldPreferences, loadRates, sendPrompt]);

    return (
        <Box>
            <Typography variant="h5" sx={{ paddingBottom: "2em", fontWeight: "700" }}>Update Your Preferences</Typography>
            <Grid container rowGap={2}>
                <Grid item xs={12}>
                    <Typography color={"primary"} variant="h4" fontWeight={600} align="center">What Type of Plans do you Prefer?</Typography>
                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={9} sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}>
                    <Typography color={"primary"} fontWeight={600} align="left">Not Interested</Typography>
                    <Typography color={"primary"} fontWeight={600} align="center">Somewhat Interested</Typography>
                    <Typography color={"primary"} fontWeight={600} align="right">Strongly Prefer</Typography>
                </Grid>
                {Object.entries(preferences.planType).map(([key, value]) => (
                <React.Fragment key={key}>
                    <Grid item xs={3}>
                        <Typography color={"primary"} fontWeight={600} px={2}>
                            {key === "shortterm" ? "Short Term" : key.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Slider
                            aria-label={`${key} plan type`}
                            value={value}
                            onChange={(e, v) => updatePlanTypePreferences(key as keyof PlanTypePreferences, v as number)}
                            step={0.1}
                            marks
                            min={0}
                            max={1}
                        />
                    </Grid>
                </React.Fragment>
            ))}
                <Grid item xs={12}>
                    <Typography color={"primary"} variant="h4" fontWeight={600} align="center">How important are these to you?</Typography>
                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={12} sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center"
                }}>
                    <Typography color={"primary"} fontWeight={600}>Not Important</Typography>
                    <Typography color={"primary"} fontWeight={600}>Indifferent</Typography>
                    <Typography color={"primary"} fontWeight={600}>Very Important</Typography>
                </Grid>
                {["lowPremium", "lowDeductible", "comprehensiveCoverage", "mentalHealth", "dental", "vision"].map((key) => {
                    const value = preferences[key as keyof Preferences];
                    const label = preferenceLabels[key as keyof Preferences];
                    return (
                        <Grid item xs={12} key={key} sx={{ mb: 2 }}>
                            <Typography>{label}</Typography>
                            <Slider
                                value={value as number}
                                onChange={(e, newValue) => updatePreferences(key as keyof Preferences, newValue as number)}
                                aria-labelledby="continuous-slider"
                                min={0}
                                max={0.9}
                                step={0.1}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <Button
                variant="contained"
                color="primary"
                disabled={promptSent}
                onClick={savePreferencesAndUpdateMessage}
            >
                Save Preferences
            </Button>
            <br />
            <br />
            {rateQuery.loading && <Typography>Refreshing Plans... <br /> <br /><CircularProgress /> </Typography>}
        </Box>
    );
};

export default PreferencesTemplate;