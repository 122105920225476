import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import { QuotitHealthPlanDetails } from "services/Interfaces";
import { QuotitPlanConverter } from "services/Firestore/Plan";
import { useNavigate } from 'react-router-dom';

const EnrollNow: React.FC = () => {
    const { firestore } = useFirebase();
    const { user, answers } = useAuth();
    const navigate = useNavigate();
    
    const [userSettings, ,] = useDocument<any>(
        doc(firestore, "users", user?.uid || "a", "settings", "chat"),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const [plan, planLoading, planError] = useDocument<QuotitHealthPlanDetails>(
        doc(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans/${userSettings?.get("selectedPlan") || "a"}`).withConverter(QuotitPlanConverter),
        {
            snapshotListenOptions: {},
        }
    );

    const [fetchEnrollmentUrl, ] = useLazyQuery<{ generateEnrollmentUrl: { redirectUrl: string } }, { effectiveDate: string, planId: string, carrierId: string }>(Queries.generateEnrollmentUrl, {
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (plan && answers) {
            fetchEnrollmentUrl({
                variables: {
                    effectiveDate: answers.get("insuranceDetails.effectiveDate"),
                    planId: plan.id.toString(),
                    carrierId: plan.data()?.carrierId?.toString() || "",
                },
            });
        }
    }, [answers, fetchEnrollmentUrl, plan]);

    async function startEnrollment() {
        if (!user?.uid || !plan) {
            console.error("Missing user or plan data", { userId: user?.uid, hasPlan: !!plan });
            return;
        }

        try {
            const planData = plan.data();
            console.log("Plan data:", planData);
            
            // Update enrollment status
            await setDoc(doc(firestore, "users", user.uid, "settings", "chat"), {
                enrollmentStatus: "started",
            }, { merge: true });

            // Create application document with all necessary fields
            const applicationData = {
                status: "draft",
                createdAt: new Date(),
                updatedAt: new Date(),
                plan: {
                    id: plan.id,
                    name: planData?.name,
                    carrier: {
                        id: planData?.carrierId,
                        name: planData?.carrier?.name
                    },
                    effectiveDate: answers?.get("insuranceDetails.effectiveDate"),
                    rate: planData?.rate,
                    rateSubsidized: planData?.rateSubsidized,
                    insuranceType: planData?.insuranceType,
                    state: answers?.get("personalDetails.state") || "", 
                    benefits: planData?.benefits,
                    planType: planData?.planType,
                    benefitLevel: planData?.benefitLevel,
                    summaryOfBenefitsLink: planData?.summaryOfBenefitsLink,
                    providerLink: planData?.providerLink
                },
                data: {
                    information: answers?.get("personalDetails") || {},
                    household: {
                        members: {
                            [user.uid]: {
                                relationship: "self",
                                firstName: answers?.get("personalDetails.firstName"),
                                lastName: answers?.get("personalDetails.lastName")
                            }
                        }
                    }
                },
                consent: [],
                documents: []
            };

            console.log("Creating application with data:", applicationData);

            // Add the application to Firestore
            const applicationsRef = collection(firestore, "users", user.uid, "applications");
            const newApplication = await addDoc(applicationsRef, applicationData);
            
            console.log("Created application with ID:", newApplication.id);

            // Navigate to the new application with correct path
            navigate(`/account/applications/${newApplication.id}`);

        } catch (error) {
            console.error("Error creating application:", error);
        }
    }

    if (planLoading) {
        return (
            <Box>
                Loading <CircularProgress />
            </Box>
        );
    }

    if (planError) {
        console.error("Plan loading error:", planError);
        return (
            <Box>
                <Typography>Error loading plan</Typography>
            </Box>
        );
    }

    const planData = plan?.data();
    const effectiveDate = answers?.get("insuranceDetails.effectiveDate");

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 2,
            backgroundColor: 'background.paper',
            padding: 2,
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider'
        }}>
            <Box>
                <Typography variant="body1">
                    Your enrollment deadline is {effectiveDate}
                </Typography>
                {planData && (
                    <>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            Plan: {planData.carrier?.name} - {planData.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {planData.planType} | {planData.benefitLevel} | ${planData.rate}/month
                        </Typography>
                    </>
                )}
            </Box>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={startEnrollment}
                disabled={!plan}
                data-testid="test-Go to application-btn"
            >
                Go to application
            </Button>
        </Box>
    );
};

export default EnrollNow;