import { PaletteColorOptions, createTheme, alpha } from "@mui/material/styles";
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

declare module '@mui/material/styles' {
  interface CustomPalette {
    beige: PaletteColorOptions;
    gray: PaletteColorOptions;
    white: PaletteColorOptions;
    black: PaletteColorOptions;
    brightOrange: PaletteColorOptions;
    redOrange: PaletteColorOptions;
    periwinkle: PaletteColorOptions;
    peach: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {

  }
  interface PaletteOptions extends CustomPalette {
  }

  interface TypographyVariants {
    superscript: React.CSSProperties;
    "body1-bold": React.CSSProperties;
    "body2-bold": React.CSSProperties;
    footer: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    superscript?: React.CSSProperties;
    "body1-bold"?: React.CSSProperties;
    "body2-bold"?: React.CSSProperties;
    footer?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    superscript: true;
    "body1-bold": true;
    "body2-bold": true;
    footer: true;
  }
}
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    white: true,
    brightOrange: true
  }
}
declare module '@mui/material/InputBase' {
  interface InputBasePropsColorOverrides {
    white: true
    grey: true
  }
}

declare module '@mui/material/FormLabel' {
  interface FormLabelPropsColorOverrides {
    white: true
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
    black: true;
  }
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
      xs: true;
      sm: true;
      ms: true;
      md: true;
      lg: true;
      xl: true;
  }
}

const theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ms: 768,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'navigation' },
          style: ({ theme }) => {
            return {
              // background: `linear-gradient(-72.47deg,rgb(${theme.palette.primary.main}) 22.16%,rgba(var(--v-global-theme-primary),.7) 76.47%)!important`,
              borderRadius: "40px",
            }
          },

        },
      ],
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 40
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0 4px 5px -2px rgba(0, 0, 0, 0.05),0 2px 10px 1px rgba(0, 0, 0, 0.05),0 2px 16px 1px rgba(0, 0, 0, 0.05)",

        }
      }
    }
  },
  palette: {
    background: {
      default: "#F4F5FA"
    },
    primary: {//deep teal
      main: '#194142',
      light: alpha("#FF9100", 0.7),
    },
    white: createColor("#FFFFFF"),
    secondary: {//bright orange
      main: '#ff9100',
      light: alpha("#FF9100", 0.9),
      contrastText: "#0F0F0F",
      100: '#ffecb3',
      200: "#ffe183"
    },
    gray: {
      main: '#707070',
      light: "#E0E0E0",
    },
    beige: {
      main: '#fef2df',// #fff2df
      dark: "#FFEBCD"
    },
    black: {
      main: '#0f0f0f'
    },
    brightOrange: createColor("#ff9100"),
    // {
    //   main: "#ff9100"
    // },
    redOrange: {
      main: '#ff5a28'
    },
    periwinkle: {
      main: '#afc3ff'
    },
    peach: {
      main: '#ffbe82'
    },
  },
  typography: {
    fontFamily: `Friends, sans-serif`,
    button: {
      textTransform: "none"
    },
    superscript: {
      fontSize: "50%",
      verticalAlign: "super",
    },
    body1: {
      color: "#4F4F4F"
    },
    "body1-bold": {
      fontWeight: 600,
      color: "#4F4F4F"
    },
    body2: {
      color: "black"
    },
    "body2-bold": {
      fontWeight: 600,
      color: "black"
    },
    h1: {
      color: "primary.main",
      fontSize: "2.25rem",
    },
    h2: {
      fontSize: "1.8rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.3rem"
    },
    h5: {
      fontSize: "1.2rem"
    },
    h6: {
      fontSize: "1.1rem"
    },
    footer: {
      color: "white"
    }
  }
});
export default theme;