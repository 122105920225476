import React, { useState, useEffect } from 'react';
import { Typography, Container, Card, Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate, Route, Routes } from "react-router-dom";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useFirebase } from '../../contexts/Firebase';

interface Message {
  data: {
    dynamicTemplate: string;
    text: string;
  };
  timestamp: string;
  type: string;
  user: boolean;
}

interface Conversation {
  id: string;
  chatType: string;
  feedbacks: any[];
  hasCobra: boolean;
  messages: Message[];
  startTimestamp: any; // Changed to 'any' to account for potential different types
}

function JamieAdmin() {
  return (
    <Routes>
      <Route path="/" element={<ConversationList />} />
    </Routes>
  );
}

function ConversationList() {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const navigate = useNavigate();
  const { firestore, auth } = useFirebase();

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error("User is not authenticated");
        }

        const db = getFirestore();
        const convosRef = collection(db, `users/${currentUser.uid}/convos`);
        const querySnapshot = await getDocs(convosRef);
        
        const fetchedConversations: Conversation[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Conversation;
          fetchedConversations.push({
            ...data,
            id: doc.id,
            startTimestamp: data.startTimestamp
          });
        });
        
        console.log("Fetched conversations:", fetchedConversations);
        setConversations(fetchedConversations);
      } catch (err) {
        console.error("Error fetching conversations:", err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchConversations();
  }, [firestore, auth]);

  const parseDate = (dateValue: any): Date => {
    if (dateValue instanceof Date) {
      return dateValue;
    }
    
    if (typeof dateValue === 'string') {
      const parsedDate = new Date(dateValue);
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
      
      const parts = dateValue.split(' ');
      if (parts.length >= 6) {
        const [month, day, year, time, timezone] = parts;
        const [hours, minutes, seconds] = time.split(':');
        const newDateString = `${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT${timezone.slice(3, -1)}`;
        return new Date(newDateString);
      }
    }
    
    if (typeof dateValue === 'object' && dateValue.seconds) {
      return new Date(dateValue.seconds * 1000);
    }
    
    // console.error("Unable to parse date:", dateValue);
    return new Date(NaN);
  };

  const conversationColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 220 },
    { field: 'chatType', headerName: 'Chat Type', width: 130 },
    { field: 'hasCobra', headerName: 'Has Cobra', width: 130, valueGetter: (params) => params.row.hasCobra ? 'Yes' : 'No' },
    { 
        field: 'startTimestamp', 
        headerName: 'Start Time', 
        width: 200, 
        valueGetter: (params) => {
          const date = parseDate(params.row.startTimestamp);
          return date;
        },
        valueFormatter: (params) => {
          const date = params.value as Date;
          return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
        },
        sortComparator: (v1, v2) => v1.getTime() - v2.getTime(),
      },
    { 
        field: 'messages', 
        headerName: 'Messages', 
        width: 130, 
        valueGetter: (params) => params.row.messages.length 
    },
    { 
        field: 'feedbacks', 
        headerName: 'Feedbacks', 
        width: 130, 
        valueGetter: (params) => params.row.feedbacks ? params.row.feedbacks.length : 0 
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params: any) => (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate(`/admin/jadmin/${params.row.id}`)}
        >
          View Details
        </Button>
      )
    }
  ];

  return (
    <Container sx={{
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      pt: "20px",
    }}>
      <Typography variant="h2" component="h1">Jamie Admin Dashboard</Typography>
      {error && <Typography color="error">Error: {error.message}</Typography>}
      {loading && <Typography>Loading...</Typography>}
      {!loading && (
        <>
          <Typography variant="h4" component="h2">Conversations</Typography>
          <Card>
            <DataGrid
              getRowId={(row) => row.id}
              autoHeight
              columns={conversationColumns}
              rows={conversations}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'startTimestamp', sort: 'desc' }],
                },
              }}
            />
          </Card>
        </>
      )}
    </Container>
  );
}

export default JamieAdmin;