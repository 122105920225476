import React, { useState, ChangeEvent } from 'react';
import { Box, Button } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { doc, setDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytes } from 'firebase/storage';
import { UploadFile } from "@mui/icons-material";

const UploadResume: React.FC = () => {
    const { firestore, storage } = useFirebase();
    const { user } = useAuth();
    const [uploading, setUploading] = useState(false);

    const uploadResume = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        setUploading(true);
        try {
            const file = e.target.files[0];
            const { name } = file;
            const uploadRef = storageRef(storage, `users/${user?.uid}/resumes/${name}`)
            await uploadBytes(uploadRef, file);
            await setDoc(doc(firestore, "users", user?.uid || "a", "settings", "chat"), {
                resume: {
                    name,
                    fileName: name,
                    path: `users/${user?.uid}/resumes/${name}`,
                    type: file.type
                },
            }, { merge: true });
        } catch (e) {
            console.error(e);
        } finally {
            setUploading(false);
        }
    }

    return (
        <Box>
            <Button 
                disabled={uploading} 
                variant="contained" 
                color="primary" 
                component="label" 
                startIcon={<UploadFile />}
            >
                Upload Resumé
                <input onChange={uploadResume} type="file" hidden />
            </Button>
        </Box>
    )
}

export default UploadResume;