import React from 'react';
import { Button } from "@mui/material";

interface ClearButtonProps {
  clear: () => void;
}

const ClearButton: React.FC<ClearButtonProps> = ({ clear }) => {
  return (
    <Button 
      onClick={clear} 
      color="warning" 
      variant="contained" 
      sx={{ padding: "5px 5px 5px 5px", margin: "10px", bgcolor: 'warning.light' }}
    >
      Clear
    </Button>
  );
};

export default ClearButton;