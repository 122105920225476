import React from 'react';
import { Button } from "@mui/material";

interface CancelButtonProps {
  cancel: () => void;
}

const CancelButton: React.FC<CancelButtonProps> = ({ cancel }) => {
  return (
    <Button 
      onClick={cancel} 
      color="warning" 
      variant="contained" 
      sx={{ padding: "5px 5px 5px 5px", margin: "10px", bgcolor: 'warning.light' }}
    >
      Cancel
    </Button>
  );
};

export default CancelButton;